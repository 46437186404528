.checkout__container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    margin-bottom: 2rem;
}

.checkout__container .form_container {
    flex: 50%;
    margin-top: 2rem;
}

.checkout__container .form_container .account_info {
    padding: 1rem 0rem;
    margin-bottom: 1rem;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
    border-bottom: 1px solid #bebebe;
}

.checkout__container .form_container .account_info a {
    color: #000000;
}

.checkout__container .form_container .account_info a:hover {
    text-decoration: underline;
}

.checkout__container .form_container .account_info .title {
    margin-bottom: .5rem;
}

.checkout__container .form_container .account_info .account_email {
    margin-bottom: .5rem;
}

.checkout__container .form_container .form_fields {
    display: flex;
    flex-direction: column;
}

.checkout__container .form_container .form_fields .form_select_field {
    display: flex;
    flex-direction: column;
}

.checkout__container .form_container .form_fields .form_select_field label {
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: .3em;
    font-family: Lato-Regular;
    font-size: .9em;

}

.checkout__container .form_container .form_fields .form_side_fields {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}

.checkout__container .form_container .form_fields .form_side_fields .city_field {
    flex: 33.33%;
}

.checkout__container .form_container .form_fields .form_side_fields .state_field {
    flex: 33.33%;
}

.checkout__container .form_container .form_fields .form_side_fields .pin_code_field {
    flex: 33.33%;
}

.checkout__container .form_container .form_fields .form_side_fields .text-field {
    width: 100%;
    margin-bottom: 0px;
}

.checkout__container .form_container .form_fields .text-field {
    margin-bottom: 0px;
}

.checkout__container .form_container .form_fields .form_side_fields .text-field .text-field-input {
    margin-bottom: 10px;
}

.checkout__container .form_container .shipping_method {
    margin-bottom: 1rem;
    margin-top: 1rem;
}

.checkout__container .form_container .shipping_method .method_display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 3rem;
    margin-top: 1rem;
    padding: 1rem;
    border: 1px solid #bebebe;
    border-radius: 5px;
    background-color: #ececec;
}

.checkout__container .form_container .shipping_method .method_display p {
    font-size: var(--font-size);
}

.checkout__container .form_container .checkout_form_div {
    margin-bottom: 2rem;
}

.checkout__container .form_container .checkout_form_div .form_title {
    margin-bottom: 1rem;
    margin-top: .5rem;
}

.checkout__container .form_container .checkout_form_div .pay_now-btn {
    width: 100%;
    margin-top: 1rem;
}

.checkout__container .form_container .checkout_form_div .payment_method_selection {
    border: 1px solid #bebebe;
    border-radius: 5px;
    margin-top: 1rem;
}

.checkout__container .form_container .checkout_form_div .payment_method_selection .form_side_fields {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}

.checkout__container .form_container .checkout_form_div .payment_method_selection .card_payment_fields {
    padding-left: 1rem;
    padding-right: 1rem;
    margin-bottom: 1rem;
}

.checkout__container .form_container .checkout_form_div .payment_method_selection .form_side_fields .text-field {
    width: 100%;
}

.checkout__container .form_container .checkout_form_div .payment_method_selection .text-field .text-field-input {
    margin-bottom: 0;
}

.checkout__container .form_container .checkout_form_div .payment_method_selection .radio_labels {
    padding: 1rem;
    margin-bottom: 0px;
}

.checkout_form_div label {
    display: block;
    margin-bottom: 8px;
    cursor: pointer;
    display: block;
    line-height: 1.4;
    margin-bottom: 10px;
    font-family: Lato-Regular;
}

.checkout_form_div input[type="checkbox"] {
    display: none;
    /* Hide the default checkbox */
}

.checkout_form_div input[type="checkbox"]+span {
    position: relative;
    padding-left: 30px;
    /* Adjust padding to accommodate the custom square */
    cursor: pointer;
}

.checkout_form_div input[type="checkbox"]+span::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1.5px solid #DC747D;
    /* Border color of the checkbox square */
    background-color: #fff;
    /* Background color of the checkbox square */
    /* Rounded corners */
}

.checkout_form_div input[type="checkbox"]:checked+span::before {
    background-color: #ffffff;
    /* Change background color when checked */
}

.checkout_form_div input[type="checkbox"]:checked+span::after {
    content: '';
    /* Remove the Unicode character for a bullet (•) */
    position: absolute;
    top: 25%;
    left: 14%;
    transform: translate(-10%, -10%);
    height: 10px;
    width: 10px;
    background-color: #DC747D;
    /* Color of the custom square */
}

.checkout__container .form_fields select {
    height: 44px;
    font-size: var(--font-size);
    background-color: inherit;
    color: inherit;
    background-position: 100%;
    background-image: url("../../../assets/images/icons/down_arrow.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 11px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-align: left;
    cursor: pointer;
    border: 1px solid;
    border-color: #c1c1c1;
    width: 100%;
    max-width: 100%;
    padding: 8px 4vw 8px 10px;
    border-radius: 0;
}

.checkout__container .form_fields select {
    outline: none;
    margin-bottom: 10px;
}

.checkout__container .items_cart_container {
    flex: 50%;
    /* height: 100vh; */
}

.checkout__container .items_cart_container .products_container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 100%;
    height: fit-content;
    max-height: vh;
    overflow-y: scroll;
    padding-top: 2rem;
    padding-right: 1rem;
}

.checkout__container .items_cart_container .products_container::-webkit-scrollbar {
    width: 3px;
}

.checkout__container .items_cart_container .products_container::-webkit-scrollbar-thumb {
    background: #666;
}

.checkout__container .items_cart_container .products_container::-webkit-scrollbar-track {
    background: #ddd;
}

.checkout__container .items_cart_container .products_container .checkout_product {
    display: flex;
    align-items: center;
    justify-content: space-around;
    gap: 1rem;
    width: 100%;
}

.checkout__container .items_cart_container .products_container .checkout_product .checkout_product_image {
    position: relative;
    width: 8rem;
}

.checkout__container .items_cart_container .products_container .checkout_product .checkout_product_image .product_item_count {
    position: absolute;
    top: -10px;
    right: -10px;
    background-color: #666;
    color: white;
    padding: .2rem .5rem;
    border-radius: 50%;
}

.checkout__container .items_cart_container .products_container .checkout_product .checkout_product_info .info {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 80%;
}

.checkout__container .items_cart_container .products_container .checkout_product .checkout_product_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 2rem;
}

.checkout__container .items_cart_container .products_container .checkout_product .checkout_product_info .info .product_title p {
    color: #000000;
    font-size: var(--font-size);
    text-transform: uppercase;
    letter-spacing: .07em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.checkout__container .items_cart_container .products_container .checkout_product .checkout_product_price p {
    font-family: var(--body-font-bold);
    font-size: var(--font-size);
    color: #1C1B1B;
    margin-top: 5px;
}

.checkout__container .items_cart_container .promo_code_container {
    display: flex;
    align-items: center;
    gap: 1rem;
}
.checkout__container .items_cart_container .promo_code_container .promo-apply-btn{
    margin-top: .6rem;
}

.checkout__container .items_cart_container .promo_code_container .text-field {
    width: 100%;
}

.checkout__container .items_cart_container .pricing_container {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.checkout__container .items_cart_container .pricing_container .pricing_subtotal,
.checkout__container .items_cart_container .pricing_container .pricing_shipping,
.checkout__container .items_cart_container .pricing_container .pricing_total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
}

.checkout__container .items_cart_container .pricing_container .pricing_subtotal .price-text,
.checkout__container .items_cart_container .pricing_container .pricing_shippin .price-textg,
.checkout__container .items_cart_container .pricing_container .pricing_total .price-text {
    font-family: var(--body-font-bold);
    color: #000000;
}

.checkout__container .items_cart_container .pricing_container .pricing_total .total_titles .total_text {
    font-family: var(--body-font-bold);
}

.checkout__container .items_cart_container .pricing_container .pricing_total .price_with_currency {
    font-family: var(--body-font-bold);
}

.checkout__container .items_cart_container .pricing_container .pricing_total .price_with_currency .currency {
    font-size: 14px;
    font-family: var(--body-font-bold);
}

@media (max-width: 1280px) {
    /* .checkout__container {
        flex-direction: column;
    } */

    .checkout__container .form_container {
        flex: 50%;
        margin-top: 2rem;
    }

    .checkout__container .items_cart_container {
        width: 50%;
    }

    .checkout__container .items_cart_container {
        height: 100%;
    }

    .checkout__container .items_cart_container .products_container {
        overflow-y: unset;
        height: 100%;
        padding-top: 0rem;
        padding-right: 0rem;
    }

}

@media (max-width: 1200px) {
    .checkout__container {
        flex-direction: column;
    }

    .checkout__container .form_container {
        order: 2;
        flex: 100%;
        margin-top: 0rem;
    }

    .checkout__container .items_cart_container {
        width: 100%;
    }


    .checkout__container .items_cart_container .products_container .checkout_product .checkout_product_image {
        width: 25%;
    }

    .checkout__container .items_cart_container .products_container .checkout_product .checkout_product_info {
        width: 55%;
    }

    .checkout__container .items_cart_container .products_container .checkout_product .checkout_product_price {
        width: 20%;
    }

    .checkout__container .items_cart_container .products_container .checkout_product .checkout_product_info .info {
        width: 100%;
    }
}

.form-error {
    color: red !important;
    margin-top: 10px !important;
    margin-bottom: 10px !important;
}

.size-select-option {
    width: 100%;
    height: 45px;
    font-size: var(--font-size);
    padding: 0 10px;
    margin-top: .5rem;
    margin-bottom: .5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.size-select-option:focus-visible {
    outline: none;
}


.my-addresses-account-details {
    margin-bottom: 1rem;
}

.add-addressOnes-btn {
    margin-bottom: 1rem;
}