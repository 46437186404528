.offer_heading {
    position: relative;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff3eb;
    color: #fff;
    padding-top: 12px;
    padding-bottom: 12px;
}

.offer_heading a {
    color: #000000;
    width: 100%;
    text-align: center;
}

.offer_heading a span {
    text-transform: uppercase;
    letter-spacing: .2em;
    font-size: .9em;
}