.sub-collection-list {
    text-align: center;
    overflow: auto;
    white-space: nowrap;
    display: flex;
    margin-top: 25px;
}

@media (min-width: 768px) {
    .sub-collection-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.sub-collection-list a {
    margin: 0 10px 10px 10px;
    width: 25%;
}

@media (min-width: 768px) {
    .sub-collection-list a {
        width: 8.3%;
    }
}

.sub-collection-list figure {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;
    margin: 0 auto;
    text-align: center;
}

@media (min-width: 768px) {
    .sub-collection-list figure {
        width: 100px;
        height: 100px;
    }
}

.sub-collection-list img {
    width: 100%;
    height: auto;
}

.sub-collection-list .title {
    text-overflow: ellipsis;
    overflow: hidden;
    letter-spacing: 0.025em;
    line-height: 2.8;
    font-size: var(--a-font-size);
    color: #000000;
}


/* Home */

.sub_collations_circle_container-home .sub-collection-list {
    text-align: center;
    overflow: auto;
    white-space: nowrap;
    display: flex;
    margin-top: 25px;
}

@media (min-width: 768px) {
    .sub_collations_circle_container-home .sub-collection-list {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
    }
}

.sub_collations_circle_container-home .sub-collection-list a {
    margin: 0 10px 10px 10px;
    width: 25%;
}

@media (min-width: 768px) {
    .sub_collations_circle_container-home .sub-collection-list a {
        width: 8.3%;
    }
}

.sub_collations_circle_container-home .sub-collection-list figure {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    overflow: hidden;
    vertical-align: middle;
    margin: 0 auto;
    text-align: center;
    border: 3px solid #ffffff;
}

@media (min-width: 768px) {
    .sub_collations_circle_container-home .sub-collection-list figure {
        width: 115px;
        height: 115px;
    }
}

.sub_collations_circle_container-home .sub-collection-list img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.sub_collations_circle_container-home .sub-collection-list .title {
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: var(--a-font-size);
    letter-spacing: 0.025em;
    line-height: 1.8;
    color: #000000;
}