.track-order-container {
    max-width: 1000px;
    margin: 20px auto;
    height: "100%";
}

.track-order-container .track-order-title h1 {
    text-align: center;
    font-size: var(--big-font-size);
    font-family: Lato-Regular;
    letter-spacing: 0.0;
    line-height: 1.8;
    text-transform: uppercase;
    color: #000000;
    margin-top: 30px;
    margin-bottom: 30px;
}

.track-order-form {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: start;
    text-align: left;
    padding: 35px;
    border: 1px solid #d9dbde;
    border-radius: 4px;
    position: relative;
    max-width: 1000px;
    margin: 0 auto;
    background: #fff;
}

.track-order-form-container {
    display: block;
}

.track-order-mobile-form-container {
    display: none;
}

.track-order-form .track-order-option1,
.track-order-form .track-order-option2 {
    width: 40%;
}

.track-order-form .track-order-divider {
    display: block;
}

.track-order-form .track-order-divider .track-order_line {
    display: block !important;
    width: 0;
    height: 50px;
    opacity: .2;
    border: 0.3px solid;
}

.track-order-form .track-order-divider .track-order_word {
    font-size: var(--font-size);
    line-height: 20px;
    margin: 8px 0;
    opacity: .6;
}

.track-order-form .track-order-divider .track-order_line_center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    height: calc(100% - 90px);
}

@media (max-width: 768px) {
    .track-order-form-container {
        display: none;
    }

    .track-order-mobile-form-container {
        display: block;
    }

}

.track-order-mobile-form-container .track-order-tabs {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 32px;
}

.track-order-mobile-form-container .track-order-tabs .track-order-tab {
    width: 168px;
    font-size: 15px;
    text-align: center;
    flex: 1;
    opacity: .45;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    line-height: 18px;
    padding: 0 10px 18px;
    color: #000000;
}

.track-order-mobile-form-container .track-order-tabs .track-order-tab.active {
    opacity: 1;
    border-bottom-width: 3px;
}

.text-field-input {
    width: 100%;
    margin-bottom: 5px;
    border: 1px solid;
    border-color: #c1c1c1;
    padding: 8px 10px;
    border-radius: 0;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 2;
}

.border-red-error{
    border: 1px solid;
    border-color: #f56565 !important;
}
.track-order-container .form-error{
    color: #f56565;
}
.track-order-container .form-error-all{
    color: #f56565;
    text-align: center;
    margin-top: 0.5rem;
}