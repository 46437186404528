.my-addresses-container {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.my-addresses-title h2 {
    font-size: var(--big-font-size);
    font-family: Lato-Regular;
    letter-spacing: 0.0;
    line-height: 1.8;
    text-transform: uppercase;
    color: #000000;
}


.my-addresses-container .add-addresses-btn {
    padding: 10px 20px;
    background-position: 150% 45%;
    min-width: 90px;
    color: #000;
    font-family: Lato-Regular;
    font-size: 14px;
    line-height: 1.42;
    border-radius: 3px;
    border: 1px solid;
    border-color: #c1c1c1;
    background-color: transparent;
}

.my-addresses-container .action-buttons .logout-btn:hover {
    border-color: #000000;
}

.my-addresses-container .action-buttons .wishlist-btn {
    color: #000;
    text-decoration: none;
    background: transparent;
    letter-spacing: 0.025em;
    line-height: 1.4;
}

.my-addresses-container .my-addresses-account-details {
    width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    margin-top: 2rem;
    padding-bottom: 2rem;
    border-bottom: 1px solid #c1c1c1;
}

.my-addresses-container .my-addresses-account-details .my-addresses-action-buttons {
    display: flex;
    align-items: flex-start;
    gap: 1rem;
}

.my-addresses-container .my-addresses-account-details .my-addresses-action-buttons .edit-address-btn {
    padding: 10px 20px;
    background-position: 150% 45%;
    min-width: 90px;
    font-family: Lato-Regular;
    font-size: 12px;
    line-height: 1.42;
    border-radius: 3px;
}

.my-addresses-container .my-addresses-account-details .my-addresses-action-buttons .delete-address-btn {
    padding: 10px 20px;
    background-position: 150% 45%;
    min-width: 90px;
    color: #000;
    font-family: Lato-Regular;
    font-size: 12px;
    line-height: 1.42;
    border-radius: 3px;
    border: 1px solid;
    border-color: #c1c1c1;
    background-color: transparent;
}

.my-addresses-container .my-addresses-account-details .my-addresses-action-buttons .delete-address-btn:hover {
    border-color: #000000;
}

.my-addresses-container .my-addresses-account-details .my-addresses-default {
    text-transform: uppercase;
    letter-spacing: .3em;
    font-size: .8em;
    margin-bottom: 10px;
    color: #000;
}

.my-addresses-container .my-addresses-account-details .profile-address {
    font-family: Lato-Regular;
    letter-spacing: 0.025em;
    line-height: 1.4;
    color: #000;
    margin: 0 0 15px;
}

.my-addresses-container .my-addresses-account-details .view-address-btn {
    color: #000;
    text-decoration: none;
    font-size: var(--font-size);
}


.no-address-found{
    margin-top: 20px;
}