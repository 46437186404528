.product-wishlist-card .overlay-items {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.checkbox input {
    margin: 0px 0 10px 10px;
    padding: 2px 5px;
    font-size: var(--a-font-size);;
    background: #DC747D;
    color: #fff;
}

.remove-btn-item {
    padding: 10px 10px 10px 0px;
}

.remove-btn-item button {
    background: #930101;
    border-radius: 100%;
    padding: 5px 4px 4px 4px;
    line-height: 0px;
    height: 25px;
    width: 25px;
    text-align: center;
    vertical-align: middle;
}

.remove-btn-item button i {
    color: #ffffff;
}

.product-wishlist-card .product-text-info {
    padding: 10px 0 6px;
    line-height: 1.3;
}

.product-wishlist-card .size-select-option {
    width: 100%;
    height: 35px;
    font-size: var(--font-size);
    padding: 0 10px;
    margin-top: .5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.product-wishlist-card .size-select-option:focus-visible {
    outline: none;
}

.product-wishlist-card .product-text-info h2 {
    color: #000000;
    font-size: var(--font-size);
    text-transform: uppercase;
    letter-spacing: .07em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-wishlist-card .product_price {
    display: flex;
    flex-direction: column;
}

.product-wishlist-card .product-final-price {
    font-family: var(--body-font-bold);
    font-size: var(--font-size);
    font-weight: 700;
    color: #1C1B1B;

    margin-top: 5px;
}

.product-wishlist-card .product-final-price .product-original-price {
    text-decoration: line-through;
    margin-right: 5px;
    margin-top: 5px;
    margin-left: 5px;
    font-size: 15px;
    color: #000000;
}

.product-wishlist-card .product-final-price .product-original-price .original-price {
    font-family: Lato-Regular;
    font-size: var(--a-font-size);;
    color: #848484 !important;
}

.product-wishlist-card .product_size {
    color: #000000;
    white-space: nowrap;
    font-family: Lato-Regular;
    display: block;
    font-size: 17px;
    margin-top: 5px;
}

.wishlist-status .move-to-cart-btn {
    margin-top: .5rem;
    font-size: .8rem;
    text-transform: initial;
    width: 100%;
    letter-spacing: 0.025em;
    font-size: 1rem;
    font-family: Lato-Regular;
}

.wishlist-status p {
    color: #69727b;
    margin-top: 5px;
    margin-bottom: 10px;
    font-size: 12px;
}

.product-wishlist-card .product_images {
    position: relative;
    overflow: hidden;
}

.product-wishlist-card .product_images img {
    height: 100%;
    width: 100%;
    /* Change 'auto' to '100%' */
    object-fit: cover;
}

.product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease;
}

.transition {
    transition: transform 0.5s;
}

@media (max-width: 769px) {
    .remove-btn-item {
        padding: 10px 10px 10px 0px;
    }
}

@media (max-width: 640px) {
    .remove-btn-item {
        padding: 10px 10px 10px 0px;
    }
}