.policy-container {
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .policy-title-center {
    text-align: center;
  }
  
  .policy-title-left {
    text-align: left;
  }
  
  .policy-titles h1 {
    font-size: var(--big-font-size);
    font-family: var(--body-font-bold);
    letter-spacing: 0.0;
    line-height: 1.8;
    text-transform: uppercase;
    color: var(--first-color);
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  
  .policy-paragraph p {
    font-size: var(--a-font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
    color: #000000;
    margin-bottom: 1.6rem;
  }
  
  .policy-paragraph p b {
    font-family: Lato-Bold;
  }
  
  .policy-paragraph ul {
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
  
  .policy-paragraph ul .para-margin {
    margin-bottom: 25px;
  }
  
  .policy-paragraph ul li::marker {
    unicode-bidi: isolate;
    font-variant-numeric: tabular-nums;
    text-transform: none;
    text-indent: 0px !important;
    text-align: start !important;
    text-align-last: start !important;
  }