.wishlist-list-card {
    margin: 1rem 0rem 0rem 0rem;
    display: flex;
    align-items: flex-start;
    flex: 1;
    gap: 1rem;
    padding: 15px 0;
    border-bottom: 1px solid #d1d1d1;
}

.wishlist-list-card img {
    width: 95px;
    min-width: 95px;
    height: auto;
}

.remove-btn-item {
    padding: 10px 10px 10px 0px;
}

.remove-btn-item button {
    background: #930101;
    border-radius: 100%;
    padding: 5px 4px 4px 4px;
    line-height: 0px;
    height: 25px;
    width: 25px;
    text-align: center;
    vertical-align: middle;
}

.remove-btn-item button i {
    color: #ffffff;
}

.wishlist-list-card .product-text-info {
    padding: 10px 0 6px;
    line-height: 1.3;
}

.wishlist-list-card .product-text-info p {
    font-size: 11px;
    color: #1C1B1B;
}

.wishlist-list-card .size-select-option {
    width: 100%;
    height: 35px;
    font-size: var(--font-size);
    padding: 0 10px;
    margin-top: .5rem;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background: none;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.wishlist-list-card .size-select-option:focus-visible {
    outline: none;
}

.wishlist-list-card .product-text-info h2 {
    font-size: var(--font-size);
    color: #000000;
    text-transform: uppercase;
    letter-spacing: .07em;
    white-space: nowrap;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.wishlist-list-card .product_price {
    display: flex;
    flex-direction: column;
}

.wishlist-list-card .product-final-price {
    font-family: var(--body-font-bold);
    font-size: var(--font-size);
    font-weight: 700;
    color: #1C1B1B;

    margin-top: 5px;
}

.wishlist-list-card .product-final-price .product-original-price {
    text-decoration: line-through;
    margin-right: 5px;
    margin-top: 5px;
    margin-left: 5px;
    font-size: 15px;
    color: #000000;
}

.wishlist-list-card .product-final-price .product-original-price .original-price {
    font-family: Lato-Regular;
    font-size: var(--a-font-size);;
    color: #848484 !important;
}

.wishlist-list-card .product_size {
    color: #000000;
    white-space: nowrap;
    font-family: Lato-Regular;
    display: block;
    font-size: 17px;
    margin-top: 5px;
}

.wishlist-list-card .wishlist-status .move-to-cart-btn {
    margin-top: .5rem;
    font-size: var(--a-font-size);
    text-transform: initial;
    width: 100%;
    letter-spacing: 0.025em;
    font-family: Lato-Regular;
}

.wishlist-list-card .wishlist-status {
    text-align: center;
}

.wishlist-list-card .wishlist-status p {
    color: #69727b;
    margin-top: 10px;
    margin-bottom: 0px;
    font-size: 12px;
}

.wishlist-list-card .wishlist-status .delete-btn {
    color: #DC747D;
    font-size: var(--a-font-size);
    margin-top: 10px;
    margin-top: 5px;
    transition: .2s ease-in-out;

    font-family: var(--typeBasePrimary), var(--typeBaseFallback);
    letter-spacing: 0.025em;
    line-height: 1.4;
}

.wishlist-list-card-other-info-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    flex: 1 1 100%;
}

.wishlist-list-card-other-info-grid .information-texts {
    width: fit-content;
}

@media (max-width: 768px) {
    .wishlist-list-card .wishlist-status {
        text-align: left;
    }
}