.filter_by_feature_on_list {
    padding-left: 22px;
    padding-right: 4px;
    margin-bottom: 1.3rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.filter_by_feature_on_list .total_number_of_products_title {
    font-family: Lato-Regular;
    font-size: var(--font-size);
}

.filter_by_feature_on_list .products_by_feature_selection select {
    height: 44px;
    font-size: var(--font-size);
    background-color: inherit;
    color: inherit;
    background-position: 100%;
    background-image: url("../../../assets/images/icons/down_arrow.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 11px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-align: left;
    cursor: pointer;
    border: 1px solid;
    border-color: #c1c1c1;
    max-width: 100%;
    padding: 8px 4vw 8px 10px;
    border-radius: 0;
}

.filter_by_feature_on_list .products_by_feature_selection select {
    outline: none;
}

@media (max-width: 769px) {
    .filter_by_feature_on_list .products_by_feature_selection {
        display: none;
    }

    .filter_by_feature_on_list {
        padding-left: 0px;
        justify-content: center;
    }
}