.USP-section {
    background-color: #fff3eb;
}

.USP-items {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: space-evenly;
}

.USP-items .USP {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
}

.USP-items .USP p {
    color: #000000;
    font-size: 16px;
    text-transform: capitalize;
    letter-spacing: .07em;
}

.USP-items .USP .USP-round {
    padding: .7rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    height: 6rem;
    width: 6rem;
    border: 2px solid;
    border-color: var(--primary-color);
    box-shadow: 0 10px 20px 0 #0000001a;
}

.USP-items .USP .USP-round img {
    height: 3rem;
    width: 3rem;
}