.cartDrawer .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    display: none;
    z-index: 1000;
    overflow: hidden !important;
}

.cart-drawer-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.overlay.show {
    display: block;
}

.cart-sliding-drawer-container {
    position: fixed;
    top: 0;
    right: -450px;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    width: 450px;
    height: 100%;
    background-color: #fff;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
    transition: right 0.3s ease-in-out;
    z-index: 1001;
}

.cart-sliding-drawer-container.open {
    right: 0;
    /* Slide in when open */
}

.cart-drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #efefef;
    padding: 1.5rem 1.5rem;
}

.cart-drawer-header h2 {
    font-family: Lato-Regular;
    font-size: var(--big-font-size);
    text-transform: uppercase;
    font-weight: 400;
    letter-spacing: 0em;
    line-height: 1.8;
    color: #000;
}

.cart-drawer-header i {
    font-size: var(--big-font-size);
    cursor: pointer;
}

/* .cart-drawer__inner {
    max-height: 100%;
    overflow-y: auto; */
/* } */

.cart-drawer__inner,
.cart-drawer__scrollable {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.cart-drawer__scrollable {
    padding-top: 20px;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.cart-drawer__footer,
.cart-drawer__scrollable {
    padding-left: 20px;
    padding-right: 20px;
}

.cart__item:first-child {
    padding-top: 10px;
}

.cart__item:last-child {
    margin-bottom: 0;
    border-bottom: 0;
}

.cart__item {
    display: flex;
    margin-bottom: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid;
    border-bottom-color: #c1c1c1;
    gap: 1rem;
}

.cart__item .cart__image {
    flex: 30%;
    position: relative;
}

.cart__item .cart__image input {
    position: absolute;
    left: 0.5rem;
    top: 0.5rem;
}

.cart__item-details {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cart__item-details .cart_item_title_area .cart__item-title {
    margin-bottom: 5px;
}
.cart__item-details .cart_item_title_area .cart__item--variants{
    margin-bottom: 5px;
}

.cart__item-details .cart_item_title_area .cart__item-title a {
    font-size: var(--font-size);
    font-family: Lato-Regular;
    color: #000;
}

.cart__item-sub {
    display: flex;
    justify-content: space-between;
}

.steppers { 
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.steppers .remove-btn-cart-item {
    color: #DC747D;
    font-size: 14px;
    transition: .2s ease-in-out;
    letter-spacing: 0.025em;
    line-height: 1.4;
    text-align: center;
    vertical-align: sub;
    padding-top: 10px;
    cursor: pointer;
}

.quantity {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border: 1px solid #efefef;
}

.quantity__minus {
    display: block;
    width: 30px;
    height: 30px;
    margin: 0;
    text-decoration: none;
    text-align: center;
    line-height: 30px;
}

.quantity__plus {
    display: block;
    width: 30px;
    height: 30px;
    margin: 0;
    text-decoration: none;
    text-align: center;
    line-height: 30px;
}

.quantity__minus:hover,
.quantity__plus:hover {
    background: #262626;
    color: #fff;
}

.quantity__minus:hover i,
.quantity__plus:hover i {
    background: #262626;
    color: #fff;
}

.quantity__minus {
    border-radius: 3px 0 0 3px;
}

.quantity__plus {
    border-radius: 0 3px 3px 0;
}

.quantity__input {
    width: 32px;
    height: 30px;
    margin: 0;
    padding: 0;
    text-align: center;
    background: #fff;
    color: #262626;
}

.quantity__minus:link,
.quantity__plus:link {
    color: #262626;
}

.quantity__minus:visited,
.quantity__plus:visited {
    color: #262626;
}

.quantity__minus,
.quantity__plus {
    font-size: 1.3rem;
    vertical-align: middle;
}

.cart__item-sub {
    line-height: 1;
}

.cart__item-price-col .cart__price {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.cart__item-sub .cart__price .offer-price {
    display: block;
    font-size: var(--a-font-size);;
    letter-spacing: 0.025em;
    line-height: 1.2;
}

.cart__item-sub .cart__price .final-amount {
    font-family: Lato-Regular;
    font-size: var(--font-size);
    color: #262626;
    letter-spacing: 0.025em;
    line-height: 1.2;
}

.cart__price--compare {
    display: flex;
    gap: 5px;
    align-items: center;
}

.cart__price--compare .mrp-price {
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.2;
}

.cart__price--compare .line-through {
    text-decoration: line-through;
    font-size: var(--font-size);
    color: #262626;
    letter-spacing: 0.025em;
    line-height: 1.2;
}

.cart-drawer__footer {
    border-top: 1px solid;
    border-top-color: #efefef;
    padding-top: 15px;
    padding-bottom: 20px;
}

@media only screen and (min-width: 769px) {
    .cart-drawer__footer {
        padding-top: 22.22222px;
    }
}


.cart__footer-sub {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
}

.cart__footer-sub .cart_footer_left_title {
    font-family: var(--body-font-bold);
    text-transform: uppercase;
    letter-spacing: 0.8em;
    letter-spacing: 0.3em;
    color: #262626;
}

.cart__footer-sub .total-amount {
    cursor: pointer;
    font-family: var(--body-font-bold);
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: 0.3em;
    letter-spacing: 0.1em;
    color: #262626;
}

.cart__checkout {
    width: 100%;
}

@media (max-width: 769px) {
    .cart-sliding-drawer-container {
        right: -390px;
        width: 390px;
    }
}


.disabled-btn-checkout:hover {
    background-color: #d5d5d5;
}

.disabled-btn-checkout {
    background-color: #d5d5d5;
    cursor: no-drop;
    width: 100%;
}


.cart-empty-message {
    font-family: var(--body-font-bold);
    text-transform: uppercase;
    letter-spacing: 0.8em;
    letter-spacing: 0.3em;
    color: #262626;
    margin-top: 1rem;
    text-align: center;
}
