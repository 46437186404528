/*=============== REUSABLE CSS CLASSES ===============*/
.grid {
  display: grid;
  gap: 1.5rem;
}

.flex {
  display: flex;
  gap: 5rem;
}

.container {
  max-width: 1500px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
}

.section {
  padding-block: 2.81rem;
}

@media (max-width: 820px) {
  .container {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media (max-width: 769px) {
  .container {
    padding-left: 17px;
    padding-right: 17px;
  }

  .section {
    padding-block: 1.4rem;
  }
}

.main {
  overflow: hidden;
  /* For animation ScrollReveal */

}

.main-div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.main-content {
  display: block;
  min-height: 300px;
}

.loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other content */
}

.loader {
  border: 8px solid #f3f3f3; /* Light grey */
  border-top: 8px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite; /* Spin animation */
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.disabled-btn {
  background-color: #d5d5d5;
  cursor: no-drop;
}

.disabled-btn:hover {
  background-color: #d5d5d5;
}

.yarl__container {
  background-color: #000000c9;
}
.yarl__slide_wrapper video {
  height: 100% !important;
  width:  100% !important;
}

.limited-text-warning{
  font-size: 12px;
  font-weight: 300;
  color: #f3bd0a;
}