.filter-form {
    margin: 0;
}

.bottom-line {
    border-bottom: 1px solid #efefef;
}

.filter-form div:first-of-type .collection-sidebar__group {
    border-top: 0;
    padding-top: 0;
}

.collection-sidebar__group:first-child {
    margin-top: 0;
}

.collection-sidebar__group {
    padding: 0px;
    margin: 0px;
}

.collection-sidebar__group {
    border-top: 1px solid;
    border-top-color: #efefef;
    padding-top: 5px;
    margin-top: 5px;
    margin-bottom: 5px;
    overflow: hidden;
}

.collection-sidebar__group .collapsible-trigger-btn {
    font-family: var(--body-font-bold);
    font-size: var(--font-size);
    line-height: 20.16px;
    font-weight: 900;
    padding: 20px 0px;
    letter-spacing: 4.32px;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

.collection-sidebar__group .collapsible-trigger-btn {
    text-align: left;
}

[data-center-text=true] .collapsible-trigger-btn {
    text-align: center;
}

.tag-list__header {
    text-align: left;
}

.collapsible-trigger {
    color: inherit;
    position: relative;
}

.collapsible-trigger-btn {
    text-transform: uppercase;
    letter-spacing: .3em;
    font-size: .8em;
    display: block;
    width: 100%;
    padding: 15px 10px 15px 0;
}

.collapsible-trigger-btn {
    text-align: left;
}

button,
input[type=submit] {
    cursor: pointer;
}


.collapsible-content {
    height: auto;
}

@media only screen and (min-width: 769px) {
    .collapsible-trigger__icon {
        width: 12px;
        height: 12px;
    }
}

.collapsible-trigger__icon--open {
    transform: scaleY(-1);
    display: block;
    position: absolute;
    right: 0;
    top: 45%;
    width: 15px;
    height: 15px;
}


.collapsible-trigger__icon {
    display: block;
    position: absolute;
    right: 0;
    top: 45%;
    width: 15px;
    height: 15px;
    transform: translateY(-50%);
}

.filter-form .collection-sidebar__group .collapsible-trigger-btn {
    font-size: var(--font-size);
    line-height: 20.16px;
    font-weight: 900;
    padding: 20px 0px;
    letter-spacing: 4.32px;
}

.filter-form .collection-sidebar__group .collapsible-trigger-btn {
    text-align: left;
}

[data-center-text=true] .collapsible-trigger-btn {
    text-align: center;
}

.collection-sidebar .tag-list__header {
    text-align: left;
}

.filter-form .collapsible-trigger {
    color: inherit;
    position: relative;
}

.filter-form .collapsible-trigger-btn {
    text-transform: uppercase;
    letter-spacing: .3em;
    font-size: .8em;
    display: block;
    width: 100%;
    padding: 15px 10px 15px 0;
}

.filter-form .collapsible-trigger-btn {
    text-align: left;
}



@media only screen and (min-width: 769px) {
    .filter-form .collapsible-content--sidebar.is-open {
        margin-bottom: 15px;
    }
}

@media only screen and (max-width: 769px) {
    .filter-form .collection-sidebar__group .collapsible-trigger-btn {
        font-size: 14px;
    }
}

@media only screen and (min-width: 769px) {
    .filter-form .collapsible-content--sidebar.is-open {
        overflow: visible;
        overflow: initial;
        visibility: visible;
        opacity: 1;
        height: auto;
    }
}

.filter-form .collapsible-content.is-open {
    visibility: visible;
    opacity: 1;
    transition: opacity 1s cubic-bezier(.25, .46, .45, .94), height .35s cubic-bezier(.25, .46, .45, .94);
}

.filter-form .collapsible-content--sidebar {
    visibility: hidden;
    overflow: hidden;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    opacity: 0;
    height: 0;
}

.filter-form .collapsible-content {
    transition: opacity .3s cubic-bezier(.25, .46, .45, .94), height .3s cubic-bezier(.25, .46, .45, .94);
}

.filter-form .collection-sidebar__group .collapsible-content__inner {
    padding-bottom: 5px;
    max-height: 300px;
    overflow-y: hidden;
    opacity: 1;
    transition: opacity .3s;
    overflow-x: hidden;
}

.filter-form .collapsible-content__inner:hover {
    overflow-y: auto;
}

.is-open .collapsible-content__inner {
    transform: translateY(0);
    transition: transform .5s cubic-bezier(.25, .46, .45, .94);
}

.filter-form .collapsible-content__inner {
    transition: transform .3s cubic-bezier(.25, .46, .45, .94);
}


.no-bullets {
    list-style: none outside;
    margin-left: 0;
}

.filter-form .collection-sidebar__group .collapsible-content__inner::-webkit-scrollbar {
    width: 3px;
}

.filter-form .collection-sidebar__group .collapsible-content__inner::-webkit-scrollbar-thumb {
    background: #666;
}

.filter-form .collection-sidebar__group .collapsible-content__inner::-webkit-scrollbar-track {
    background: #ddd;
}

/* ////akjshdasd */
.filter-form .filter-scrollable {
    max-height: 300px;
    /* Set the maximum height as needed */
    /* Enable vertical scrolling when content exceeds the max height */
}


.filter-form .filter-scrollable label {
    display: block;
    margin-bottom: 8px;
    cursor: pointer;
    display: block;
    line-height: 1.4;
    margin-bottom: 10px;
    font-size: var(--a-font-size);
    font-family: Lato-Regular;
}

.filter-form .filter-scrollable input[type="checkbox"] {
    display: none;
    /* Hide the default checkbox */
}

.filter-form .filter-scrollable input[type="checkbox"]+span {
    position: relative;
    padding-left: 30px;
    /* Adjust padding to accommodate the custom square */
    cursor: pointer;
}

.filter-form .filter-scrollable input[type="checkbox"]+span::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1.5px solid #DC747D;
    /* Border color of the checkbox square */
    background-color: #fff;
    /* Background color of the checkbox square */
    /* Rounded corners */
}

.filter-form .filter-scrollable input[type="checkbox"]:checked+span::before {
    background-color: #ffffff;
    /* Change background color when checked */
}

.filter-form .filter-scrollable input[type="checkbox"]:checked+span::after {
    content: '';
    /* Remove the Unicode character for a bullet (•) */
    position: absolute;
    top: 25%;
    left: 14%;
    transform: translate(-10%, -10%);
    height: 10px;
    width: 10px;
    background-color: #DC747D;
    /* Color of the custom square */
}


.filter-form .filter-scrollable .rangeSlider {
    display: flex;
    flex-direction: column;
}

.filter-form .filter-scrollable .rangeSlider .multi-range-slider {
    border: none;
    border-radius: 0px;
    box-shadow: none;
    padding: 10px 10px 10px 10px;
}

.filter-form .filter-scrollable .rangeSlider .multi-range-slider .bar-left,
.filter-form .filter-scrollable .rangeSlider .multi-range-slider .bar-right {
    background-color: #F4F4F4;
    border-radius: 10px 0px 0px 10px;
    box-shadow: none;
    padding: 4px 0px;
}

.filter-form .filter-scrollable .rangeSlider .multi-range-slider .bar-inner {
    box-shadow: none;
    border: none;
}

.filter-form .filter-scrollable .rangeSlider .multi-range-slider .thumb::before {
    border: 2px solid #fff;
    box-shadow: none;
    margin: -7px -12px;
    /* width: 12px;
    height: 12px; */
}

.filter-form .filter-scrollable .rangeSlider .multi-range-slider .thumb:active .caption {

    display: none;
}

.filter-form .filter-scrollable .rangeSlider .rangeSlider_labels {
    display: flex;
    justify-content: space-between;
    align-items: center;
}