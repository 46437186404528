.additional_offers-section h2 {
    font-size: 20px;
    display: block;
    letter-spacing: 0.025em;
    line-height: 1.4;
    font-family: var(--body-font-bold);
    color: #000;
}

.offer:hover {
    background: #fdeee7;
    border: 1px dashed #DC747D;
}

.offer {
    display: flex;
    align-items: center;
    border: 1px dashed #c3c3c3;
    padding: 5px;
    margin-top: 10px;
    font-size: 14px;
}

.offer figure {
    width: 24px;
    margin: 0 10px 0 0;
}

.offer figure img {
    max-width: 100%;
}

.offer b,
strong {
    font-family: var(--body-font-bold);
}

.offer p {
    margin: 5px 0px;
    letter-spacing: 0.025em;
    line-height: 1.4;
    font-family: var(--body-font);
    color: #000;
}

.coupon-bar {
    margin-top: 5px;
}

.offer .coupon-bar .copy-code {
    cursor: pointer;
    border: 1px dashed #DC747D;
    padding: 2px 3px;
    margin-left: 10px;
    margin-top: 5px;
}

.offer:hover .copy-code {
    background: #DC747D;
    color: #fff;
}