.text-field {
    margin-bottom: 10px;
}

.text-field .text-field-label {
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: .3em;
    font-family: Lato-Regular;
    font-size: .9em;
}

.text-field .text-field-input {
    width: 100%;
    margin-bottom: 5px;
    border: 1px solid;
    border-color: #c1c1c1;
    padding: 8px 10px;
    border-radius: 0;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.5;
}

.border-red-error{
    border: 1px solid;
    border-color: #f56565 !important;
}

.field-error-text {
    text-transform: uppercase;
    letter-spacing: .3em;
    font-family: Lato-Regular;
    font-size: .7em;
    color: red;
}

.text-field .text-field-input:focus {
    border: 1px solid #000;
}