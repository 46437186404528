.tracking-detail-item-mobile {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 10px;
    margin-bottom: 20px;
  }
  
  .label {
    font-weight: bold;
  }
  