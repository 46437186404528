/* CollapsibleList.css */
.product_collapsible-list {
    border: 1px solid #c1c1c1;
    overflow: hidden;
    /* Hide overflow to prevent content flash */
}

.product_collapsible-list-btn {
    position: relative;
    width: 100%;
    padding: 15px;
    cursor: pointer;
    text-align: center;
    background-color: #fff;
    color: #000;
    border: none;
    outline: none;
    text-transform: uppercase;
    letter-spacing: .3em;
    line-height: 1.4rem;
    font-size: var(--a-font-size);
    transition: background-color 0.3s;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

.product_collapsible-list.open .product_collapsible-list-btn {
    background-color: #ffffff;
    /* Change background color when open */
}

.product_collapsible-list-content {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
    padding: 0px 20px;
    line-height: 2;
    font-size: var(--a-font-size);
    font-family: Lato-Regular;
    letter-spacing: 0.025em;
    color: #000;
    /* Add transition for smooth height change */
}

.product_collapsible-list.open .product_collapsible-list-content {
    padding: 0px 20px 20px 20px;
    max-height: 1000px;
    /* Set a maximum height or adjust as needed */
    transition: max-height 0.3s ease-in-out;
    /* Add transition for smooth height change */
}

.product_collapsible-list-btn img {
    height: 12px;
    position: absolute;
    right: 1rem;
    top: 40%;
    transform: rotate(0deg);
    transition: transform 0.3s;
}

.product_collapsible-list.open .product_collapsible-list-btn img {
    transform: rotate(180deg);
}