.hero-slider .swiper {
    width: 100%;
    height: 100%;
}

.hero-slider .swiper-slide {
    text-align: center;
    font-size: var(--font-size);
    background: #ffffff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    height: fit-content;
}

.hero-slider .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hero-slider .swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.hero-slider .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    margin-bottom: 1%;
}


.hero-slider .swiper-pagination-bullet {
    background: #DEB4B4 !important;
    opacity: 1 !important;
}

.hero-slider .swiper-pagination-bullet-active {
    background-color: #fff !important;
}

.hero-slider .desktop_hero_slider {
    display: block !important;
}

.hero-slider .mobile_hero_slider {
    display: none !important;
}

@media (max-width: 769px) {
    .hero-slider .desktop_hero_slider {
        display: none !important;
    }

    .hero-slider .mobile_hero_slider {
        display: block !important;
    }
}