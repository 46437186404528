/* @font-face {
  font-family: Lato-Regular;
  src: url(./assets/fonts/tenor_sans/Lato-Regular.woff);
}

@font-face {
  font-family: Lato-Regular;2;
  src: url(./assets/fonts/tenor_sans/Lato-Regular.woff2);
}

@font-face {
  font-family: Lato-Regular;
  src: url(./assets/fonts/twentieth_century/twentiethcentury_n4.woff);
}

@font-face {
  font-family: twentiethcentury2;
  src: url(./assets/fonts/twentieth_century/twentiethcentury_n4.woff2);
}

@font-face {
  font-family: Lato-Bold;
  src: url(./assets/fonts/twentieth_century/twentiethcentury_n6.woff);
}

@font-face {
  font-family: twentiethcentury_n62;
  src: url(./assets/fonts/twentieth_century/twentiethcentury_n6.woff2);
} */

@font-face {
  font-family: Lato-Black;
  src: url(assets/fonts/Lato-Black.ttf);
}
@font-face {
  font-family: Lato-BlackItalic;
  src: url(assets/fonts/Lato-BlackItalic.ttf);
}
@font-face {
  font-family: Lato-Bold;
  src: url(assets/fonts/Lato-Bold.ttf);
}
@font-face {
  font-family: Lato-BoldItalic;
  src: url(assets/fonts/Lato-BoldItalic.ttf);
}
@font-face {
  font-family: Lato-Italic;
  src: url(assets/fonts/Lato-Italic.ttf);
}
@font-face {
  font-family: Lato-Light;
  src: url(assets/fonts/Lato-Light.ttf);
}
@font-face {
  font-family: Lato-LightItalic;
  src: url(assets/fonts/Lato-LightItalic.ttf);
}
@font-face {
  font-family: Lato-Regular;
  src: url(assets/fonts/Lato-Regular.ttf);
}
@font-face {
  font-family: Lato-Thin;
  src: url(assets/fonts/Lato-Thin.ttf);
}
@font-face {
  font-family: Lato-ThinItalic;
  src: url(assets/fonts/Lato-ThinItalic.ttf);
}

:root {
  --header-height: 5rem;
  /*========== Colors ==========*/
  /*Color mode HSL(hue, saturation, lightness)*/
  --first-color: #DC747D;
  --title-color: #DC747D;
  --secondary-color: #fff3eb;
  --text-color: hsl(227, 5%, 38%);
  --white-color: #FFFFFF;
  --body-color: #FFF;
  --container-color: #FFF;
  --swiper-theme-color: #0000000;
  /*========== Font and typography ==========*/
  /*.5rem = 8px | 1rem = 16px ...*/
  --body-font: Lato-Regular, sans-serif;
  /* --body-font: 'Urbanist', sans-serif; */
  --body-font-bold: Lato-Bold, sans-serif;
  --big-font-size: 1.5rem;
  --font-size: 14px;
  --a-font-size: 13px;
  --p-font-size: 1.125rem;
  --button-font-size: 1rem;
  --normal-font-size: 1rem;
  --small-font-size: .813rem;
  --smaller-font-size: .75rem;

  /*========== Font weight ==========*/
  --font-weight-100: 100;
  --font-weight-200: 200;
  --font-weight-300: 300;
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-800: 800;

  /*========== z index ==========*/
  --z-tooltip: 10;
  --z-fixed: 100;
}

@media screen and (min-width: 640px) {
  :root {
    --big-font-size: 1.7rem;
    --font-size: 16px;
    --a-font-size: 14px;
    --p-font-size: 1.125rem;
    --button-font-size: 1rem;
    --normal-font-size: 1rem;
    --small-font-size: .813rem;
    --smaller-font-size: .75rem;
  }
}



/*=============== BASE ===============*/
* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  scroll-behavior: smooth;
  overflow-x: hidden;
}

input,
button,
body {
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  overflow-x: hidden;
}

body {
  background-color: var(--body-color);
  font-size: 1rem;
  color: var(--text-color);
  transition: background .4s;
  /* for dark mode animation */
  overflow-x: hidden;
}

input,
button {
  border: none;
  outline: none;
}

button {
  background-color: transparent;
  cursor: pointer;
}

h1,
h2,
h3,
h4 {
  color: var(--title-color);
  font-weight: var(--font-semi-bold);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

.btn {
  position: relative;
  overflow: hidden;
  transition: background .2s ease 0s;
  background-color: #DC747D;
  color: #fff;
  line-height: 1.42;
  text-decoration: none;
  text-align: center;
  white-space: normal;
  font-size: calc(18px - 4px);
  font-size: var(--a-font-size);;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: .3em;
  display: inline-block;
  padding: 11px 20px;
  margin: 0;
  width: auto;
  min-width: 90px;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  -webkit-user-select: none;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  border-radius: 3px;
  color: #fff;
  background: #DC747D;
}

.btn:hover {
  background: #000000;
  transition-delay: .25s;
}

.infinite-scroll-component{
  height: unset !important;
  overflow: unset  !important;
}