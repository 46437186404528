.address-form-container {
    width: 80%;
}

.address-form-title {
    margin-bottom: 1rem;
}

.address-form-title h1 {
    text-transform: uppercase;
    font-size: var(--big-font-size);
    font-family: Lato-Regular;
    letter-spacing: 00;
    line-height: 1.8;
    color: #000;
}

.address-form {
    display: flex;
    flex-direction: column;
}

.address-form .flex-form-field {
    display: flex;
    gap: 1rem;
}

.address-form .flex-form-field .text-field {
    width: 50%;
}

.address-form .flex-form-field .select-field {
    width: 50%;
}

.address-form .set-as-default-checkbox label {
    width: fit-content;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 1rem;
    text-transform: uppercase;
    letter-spacing: .3em;
    font-size: .8em;
    margin-bottom: 30px;
}

.address-form .add-address-btn {
    width: fit-content;
    margin-bottom: 20px;
}

.address-form .cancel-address-btn {
    text-align: left;
    font-family: Lato-Regular;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
    color: #000;
    margin: 0 0 15px;
}

.border-red-error{
    border: 1px solid;
    border-color: #f56565 !important;
}