.new_arrivals {
    margin: 0 0 0 -22px;
}

.new_arrivals .new-arrivals_product_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.new_arrivals .products {
    margin-bottom: 30px;
    padding-left: 22px;
    width: 25%;
}

.btn-container{
    display: flex;
    justify-content: center;
}

.shop-now {
    background-color: #DC747D;
    color: #fff;
    overflow: hidden;
    padding: 8px 5px;
   
    font-size: var(--font-size);
    font-family: Lato-Regular;
}

@media (max-width: 769px) {
    .new_arrivals .new-arrivals_product_list {
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: hidden;
        overflow-x: scroll;
    }

    .new_arrivals .new-arrivals_product_list .products {
        margin-bottom: 2px;
        width: auto;
    }

    .new_arrivals .new-arrivals_product_list .products .product-card {
        font-size: .75rem;
        width: 39vw;
        flex: 0 0 39vw;
        display: inline-block;
        float: none;
        white-space: normal;
    }
}