.navigation_bar {
    position: fixed;
    width: 100%;
    bottom: 0px;
    left: 0px;
    background: #fff;
    z-index: 97;
    -webkit-box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.14);
    box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.14);
}

.navigation_bar {
    display: none;
}

@media (max-width: 769px) {
    .navigation_bar {
        display: block;
    }
}

.navigation_bar ul {
    display: flex;
    justify-content: space-between;
    list-style: none;
    vertical-align: center;
    margin: 0px;
    padding: 5px 0px;
}

.navigation_bar ul li {
    padding: 5px;
    padding-top: 8px;
    margin: 0px;
    text-align: center;
    font-size: 11px;
    text-transform: uppercase;
    width: 20%;
    letter-spacing: 0px;
}

.navigation_bar ul li a {
    color: #000;
    text-decoration: none;
    background: transparent;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.navigation_bar ul li a figure {
    margin: 0 auto;
}

.navigation_bar ul li a img {
    max-width: inherit;
    width: auto;
    height: 18px;
}