.product-quickview .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    display: none;
    z-index: 500;
    overflow: hidden !important;
}

.quick_view-drawer-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.overlay.show {
    display: block;
}

.quick_view-sliding-drawer-container {
    position: fixed;
    
    top: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
    transition: fade 0.3s ease;
    z-index: 501;
}

.quick_view-sliding-drawer-container.open {
    right: 15%;
    left: 15%;
    bottom: 5%;
    top: 5%;
    /* Slide in when open */
}


.quick_view-drawer-header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.quick_view-drawer-header i {
    font-size: 1.5rem;
    cursor: pointer;
    align-self: flex-end;
    padding: 12px;
}

/* .quick_view-drawer__inner {
    max-height: 100%;
    overflow-y: auto; */
/* } */

.quick_view-drawer__inner,
.quick_view-drawer__scrollable {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.quick_view-drawer__scrollable {
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}


.quick_view-drawer__scrollable {
    padding-left: 0px;
    padding-right: 0px;
}

.quick_view__items {
    padding-left: 20px;
    padding-right: 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}

.details_image_grid img {
    transition: .5s ease all;
}

.details_image_grid img:hover {
    -webkit-transform: scale(1.04);
    transform: scale(1.04);
}

.details_image_grid .details_image-sizing {
    width: 49.5%;
    float: left;
    margin-bottom: 1%;
    position: relative;
}

.details_image_grid .details_image-sizing:nth-child(2n) {
    margin-left: 1%;
}

.details_image_grid .image-grid-imageContainer {
    height: 0;
    padding-top: 124.33333333333331%;
    overflow: hidden;
    position: relative;
    border: 1px solid #f5f5f6;
}

.details_image_grid .image-grid-imageContainer img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    will-change: transform;
}

.details_image_grid .details_image-sizing:hover {
    cursor: crosshair;
    cursor: -webkit-image-set(url("../../assets/images/icons/hover_zoom_icon.png") 1x, url("../../assets/images/icons/hover_zoom_icon.png") 2x), crosshair;
}

.product_all_details {
    padding-left: 0px;
    width: 100%;
}

.product-single__title {
    margin-bottom: 5px;
    word-wrap: break-word;
    font-size: var(--big-font-size);
    font-family: Lato-Regular;
    letter-spacing: 0.0;
    line-height: 1.8;
    color: #000;
}

.product_star_rating {
    display: flex;
    align-items: center;
    gap: .3rem;
    margin-bottom: 10px;
}

.product_star_rating i {
    font-size: 1.3rem;
    color: #ffa900;
}

.product_star_rating span {
    color: #000;
    font-size: var(--font-size);
    line-height: 1.4;
    font-family: Lato-Regular;
    margin-top: 5px;
}

.product_all_pricing {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 25px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.product_all_pricing_prices {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 1rem;
}

.product_all_pricing_prices span.prices_final-price {
    font-weight: 700;
    font-size: 30px;
    color: inherit !important;
    cursor: pointer;
    color: #1C1B1B !important;
    letter-spacing: 0.025em;
}

.product_all_pricing_prices span.prices_main-price {
    color: inherit !important;
    cursor: pointer;
    font-size: var(--font-size);
    text-decoration: line-through;
    letter-spacing: 0.025em;
    line-height: 1.5;
}

.product_all_pricing_prices span.prices_discount {
    cursor: pointer;
    font-size: var(--font-size);
    color: #fff;
    background: #DC747D;
    padding: 2px 5px;
    letter-spacing: 0.025em;
    line-height: 1.5;
}

.product_all_pricing_savings {
    color: #ff4e4e;
    white-space: nowrap;
}

.product_all_pricing_savings .product_all_pricing_saving_price {
    cursor: pointer;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.5;
}

.product_all_pricing_savings .product_all_pricing_saving_price b {
    font-family: var(--body-font-bold);
}

.product_all_pricing_savings .text-info-label {
    font-size: 15.5px;
    color: #000;
}

.product_details_sizes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.product_details_sizes_chart {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.product_details_sizes_chart p {
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: .3em;
    font-size: 14.5px;
    cursor: default;
    line-height: 1.5;
}

.size_chart-btn {
    padding: 5px 10px;
    color: #DC747D;
    cursor: pointer;
    font-weight: 700;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
    font-family: var(--body-font-bold);
}

.size_chart-btn i {
    color: #DC747D;
    cursor: pointer;
    font-weight: 700;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
}

.product_sizes_radio {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5rem;
    margin-bottom: 10px;
}

.quick_view-drawer__inner .add_to_cart_btn .btn {
    width: 100%;
    margin-bottom: 25px;
}

.product_sales_points {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
    margin-bottom: 25px;
}

.product_sales_point {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.product_sales_point img {
    width: 25px;
    height: 25px;
}

.product_sales_point .globalIcon {
    width: 21px;
    height: 21px;
    margin: 3px;
}

.product_sales_point .icon {
    position: relative;
    width: 25px;
    height: 25px;
}

.icon--inventory:before {
    animation: inventory-pulse 2s linear infinite;
}

@keyframes inventory-pulse {
    0% {
        opacity: .5;
    }

    100% {
        transform: scale(2.5);
        opacity: 0;
    }
}

.icon--inventory:after,
.icon--inventory:before {
    width: 9px;
    height: 9px;
    background: #54c63a;
    border-radius: 9px;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    margin: 8px;
}

.icon--inventory:after,
.icon--inventory:before {
    width: 9px;
    height: 9px;
    background: #54c63a;
    border-radius: 9px;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    margin: 8px;
}


.specifications {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.specifications .specifications_heading h3 {
    text-transform: uppercase;
    font-family: Lato-Regular;
    font-size: var(--font-size);
    letter-spacing: 0.0;
    line-height: 1.8;
    color: #000;
}

.product_other_info {
    margin-bottom: 25px;
}

.product_social_media_links {
    margin-top: 25px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.product_social_media_links a {
    color: #000;
    font-size: var(--font-size);
    display: inline-block;
    vertical-align: middle;
    letter-spacing: 0.025em;
    line-height: 1.4;
}

.product_social_media_links i {
    margin-right: .5rem;
    font-size: var(--font-size);
}

@media (max-width: 1440px) {
    .quick_view-sliding-drawer-container.open {
        right: 10%;
        left: 10%;
        bottom: 5%;
        top: 5%;
        /* Slide in when open */
    }
}

@media (max-width: 1024px) {
    .quick_view__items {
        gap: 15px;
    }
}

@media (max-width: 769px) {

    .quick_view__items {
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }

    .quick_view-sliding-drawer-container {
        right: -350px;
        width: auto;
    }

    .product_all_details {
        width: 100%;
    }
}

@media (max-width: 640px) {
    .quick_view-sliding-drawer-container.open {
        right: 5%;
        left: 5%;
        bottom: 5%;
        top: 2%;
    }
}

.error-text{
    color: #ff4e4e;
    white-space: nowrap;
    margin-bottom: 0.5rem;
}

.empty-div{
    margin-bottom: 15px;
}