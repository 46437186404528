.not-found-container {
    margin-bottom: 5rem;
}

.not-found-title {
    text-align: center;
}

.not-found-title h1 {
    font-size: var(--big-font-size);
    font-family: Lato-Regular;
    letter-spacing: 0.0;
    line-height: 1.8;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 30px;
}

.not-found-title p {
    margin-bottom: 1rem;
}

.not-found-title .continue-shopping {
    text-decoration: none;
    border-bottom: 1px solid;
    border-bottom-color: #00000026;
    position: relative;
    display: inline-block;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
    color: #000000;
    cursor: pointer;
}

.shop-by-category-container .shop-by-category-title {
    text-align: center;
    margin-bottom: 2rem;
}

.shop-by-category-container .shop-by-category-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1.5rem;
}

.shop-by-category-container .shop-by-category-items .shop-by-category-card {
    position: relative;
}

.shop-by-category-container .shop-by-category-items .shop-by-category-card .ategory-card-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.shop-by-category-container .shop-by-category-items .shop-by-category-card .category-labels-button {
    position: absolute;
    left: 15px;
    right: 15px;
    bottom: 15px;
    text-align: center;
}

.shop-by-category-container .shop-by-category-items .shop-by-category-card .category-labels-button .title-label {
    display: block;
    padding: 5px 0px;
    color: #fff;
    text-shadow: 0 0 50px #000;
    border: none;
    margin: 0 0 10px;
    text-transform: uppercase;
    font-size: var(--font-size);
    letter-spacing: 0.25em;
    line-height: 1em;
    background-color: #00000063;
    opacity: .9;
    transition: opacity .8s ease;
}

.shop-by-category-container .shop-by-category-items .shop-by-category-card .category-labels-button .see-all {
    display: none;
}

.shop-by-category-container .shop-by-category-items .shop-by-category-card .category-labels-button .see-all a {
    color: #ffff;
    border: 1px solid;
    padding: 0px 8px;
    font-size: 20px;
    letter-spacing: 0.025em;
    line-height: 1.4;
}

.shop-by-category-container .shop-by-category-items .shop-by-category-card:hover .category-labels-button {
    top: 50%;
    transform: scale(1.03);
    transition: all .8s ease;
}

.shop-by-category-container .shop-by-category-items .shop-by-category-card:hover .see-all {
    display: block;
}


@media (max-width: 768px) {
    .shop-by-category-container .shop-by-category-items {
        grid-template-columns: repeat(2, 1fr);
    }

    .shop-by-category-container .shop-by-category-items .shop-by-category-card .category-labels-button .title-label {
        font-size: .8em;
    }
}