.loader-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.938);
    position: fixed;
    z-index: 1000000;
}

.loader {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    display: inline-block;
    border-top: 3px solid #dc747d;
    border-right: 3px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loader-container svg {
    width: 125px;
    height: 125px;
}

.loader-container svg path {
    display: flex;
    align-items: center;
    justify-content: center;
}

/* Hide specific SVG elements by default */
.dress,
.mexi,
.skirt {
    visibility: hidden;
}