.size_chart_container {

    display: flex;
    flex-direction: column;
    align-items: center;
}

.size_chart_container .size-chart-tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #d1d1d1;
    width: fit-content;
    border-radius: 100px;
    padding: 3px;
}

.size_chart_container .size-chart-tabs .size-chart-tab {
    cursor: pointer;
    padding: 5px 30px;
    border-radius: 100px;
    border-bottom: none;
    font-size: 14px;
}

.size_chart_container .size-chart-tabs .size-chart-tab.active {
    background: #ffecec;
    color: #000000;
    border-radius: 100px;
}

.size_chart_container .size-chart-tab-content {
    padding: 15px 0px;
    width: 100%;
}

.size_chart_container .size-chart-tab-content h3 {
    margin-bottom: 10px;
}

.size_chart_container .size-chart-tab-content .size-chart-sample-image {}

.size_chart_container .size-chart-tab-content p {
    margin: 15px 0px;
    color: #666666;
    font-size: 14px;
    letter-spacing: 0.025em;
    line-height: 1.4;
}

.size_chart_container .size-chart-tab-content p strong {
    display: block;
    color: #000000;
    font-size: 15px;
}


.size-chart-tab-content table {
    display: table;
    border-spacing: 0;
    width: 100%;
    border-spacing: 1px;
    position: relative;
    border: 0 none;
    background: transparent;
    border-collapse: separate;
}

.size-chart-tab-content table th:first-child {
    border-left: 0px;
}

.size-chart-tab-content table th {
    font-family: Lato-Regular;
    background: #DC747D;
    border: none;
    color: #fff;
    font-size: 12px;
    border-left: 1px solid #fff;
    padding: 7px 10px;
    text-align: center;
}

.size-chart-tab-content table td {
    font-family: Lato-Regular;
    font-size: 15px;
    text-align: center;
    border: 0px !important;
    border-top: 1px solid #e8e8e8 !important;
    padding: 7px 10px;
}