/* .occasion-section .section_heading_container {
    margin-right: 0;
} */

.occasion-section .occasion-items {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
}

@media screen and (max-width: 768px) {
    .occasion-section .section_heading_container {
        margin-right: 0;
    }

    .occasion-section .occasion-items {
        grid-template-columns: repeat(2, 1fr);
    }
}