.desktop_banner_slider {
    display: block;
}

.mobile_banner_slider {
    display: none;
}

@media (max-width: 769px) {
    .desktop_banner_slider {
        display: none !important;
    }

    .mobile_banner_slider {
        display: block !important;
        width: 100%;
    }
}

.other_banner {
    display: flex;
    justify-content: center
}