.canccellation-drawer-content .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    display: none;
    z-index: 500;
    overflow: hidden !important;
}

.canccellation-drawer-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.overlay.show {
    display: block;
}

.canccellation-sliding-drawer-container {
    position: fixed;

    top: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
    transition: fade 0.3s ease;
    z-index: 501;
}

.canccellation-sliding-drawer-container.open {
    right: 30%;
    left: 30%;
    bottom: 15%;
    top: 15%;
    /* Slide in when open */
}

.canccellation-drawer__inner,
.canccellation-drawer__scrollable {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.canccellation-drawer__scrollable {
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}


.canccellation-drawer__scrollable {
    padding-left: 0px;
    padding-right: 0px;
}

.canccellation__items {
    padding-left: 20px;
    padding-right: 20px;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;
}

.close-button {
    color: red;
    font-weight: 900;
    font-size: larger;
}

.main-container {
    display: block;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    margin-top: 1rem;
}

.image-cancel {
    width: 20%;
}


.question-label {
    margin-top: 0.9rem;
}

.btn-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-area-cancel {
    min-width: 100%;
    max-width: 100%;
    min-height: 100px;
    outline: none;
    border: 1px solid;
    border-color: #c1c1c1;
    padding: 8px 10px;
    border-radius: 0;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.error-text-cancel {
    color: red;
}

.refund-text {
    color: orange;
    margin-top: 10px;
    margin-bottom: 10px;
}

.order_cancellation_items {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.order_cancellation_items .cancellation_item_info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.order_cancellation_items .cancellation_item_info .image-cancel {
    flex: 25%;
}

.order_cancellation_items .cancellation_item_info .cancellation-text-infos {
    flex: 75%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.order_cancellation_items .cancellation_item_info .cancellation-text-infos h2 {
    color: #000000;
    font-size: var(--font-size);
    text-transform: uppercase;
    letter-spacing: .07em;
    overflow: hidden;
    text-overflow: ellipsis;
}

.order_cancellation_items .question-label {
    width: 100%;
}

.order_cancellation_items label {
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: .3em;
    font-family: Lato-Regular;
    font-size: .9em;
}

.form-container-bank {
    padding-top: 2rem;
    width: 100%;
}

.terms-condition-checkbox-container input[type="checkbox"] {
    display: none;
    /* Hide the default checkbox */
}

.terms-condition-checkbox-container label{
    font-size: smaller;
}

.terms-condition-checkbox-container input[type="checkbox"]+span {
    position: relative;
    padding-left: 30px;
    /* Adjust padding to accommodate the custom square */
    cursor: pointer;
}

.terms-condition-checkbox-container input[type="checkbox"]+span::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1.5px solid #DC747D;
    /* Border color of the checkbox square */
    background-color: #fff;
    /* Background color of the checkbox square */
    /* Rounded corners */
}

.terms-condition-checkbox-container input[type="checkbox"]:checked+span::before {
    background-color: #ffffff;
    /* Change background color when checked */
}

.terms-condition-checkbox-container input[type="checkbox"]:checked+span::after {
    content: '';
    /* Remove the Unicode character for a bullet (•) */
    position: absolute;
    top: 25%;
    left: 14%;
    transform: translate(-10%, -10%);
    height: 10px;
    width: 10px;
    background-color: #DC747D;
    /* Color of the custom square */
}



@media (max-width: 1440px) {}

@media (max-width: 1440px) {
    .canccellation-sliding-drawer-container.open {
        right: 25%;
        left: 25%;
    }
}

@media (max-width: 1280px) {
    .canccellation-sliding-drawer-container.open {
        right: 20%;
        left: 20%;
    }
}

@media (max-width: 1024px) {
    .canccellation-sliding-drawer-container.open {
        right: 18%;
        left: 18%;
    }
}

@media (max-width: 868px) {
    .canccellation-sliding-drawer-container.open {
        right: 16%;
        left: 16%;
    }
}

@media (max-width: 768px) {
    .canccellation-sliding-drawer-container.open {
        right: 10%;
        left: 10%;
        bottom: 10%;
        top: 10%;
    }

    .main-container {
        padding: 0;
    }

    .order_cancellation_items .cancellation_item_info .image-cancel {
        flex: 50%;
    }

    .order_cancellation_items .cancellation_item_info .cancellation-text-infos {
        flex: 50%;
    }

    .order_cancellation_items .cancellation_item_info .cancellation-text-infos h2 {
        font-size: 14px;
    }

    .order_cancellation_items label {
        font-size: .8em;
    }
}



@media (max-width: 640px) {
    .canccellation-sliding-drawer-container.open {
        right: 5%;
        left: 5%;
    }
}

@media (max-width: 475px) {
    .canccellation-sliding-drawer-container.open {
        right: 2%;
        left: 2%;
    }
}