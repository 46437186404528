.details_product_container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.details_image_grid {
    float: left;
    width: 100%;
    gap: .5rem;
    width: 100%;
}

.common-clearfix:after,
.common-clearfix:before {
    content: " ";
    display: table;
}

.details_all_details {
    padding-left: 45px;
    width: 100%;
}

.mobile_image_grid {
    display: none;
}

.details_image_grid img {
    transition: .5s ease all;
}

.details_image_grid img:hover {
    -webkit-transform: scale(1.04);
    transform: scale(1.04);
}

.details_image_grid .details_image-sizing {
    width: 49.5%;
    float: left;
    margin-bottom: 1%;
    position: relative;
}

.details_image_grid .details_image-sizing:nth-child(2n) {
    margin-left: 1%;
}

.details_image_grid .image-grid-imageContainer {
    height: 0;
    padding-top: 124.33333333333331%;
    overflow: hidden;
    position: relative;
    border: 1px solid #f5f5f6;
}

.details_image_grid .image-grid-imageContainer img {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    will-change: transform;
}

.details_image_grid .details_image-sizing:hover {
    cursor: crosshair;
    cursor: -webkit-image-set(url("../../../assets/images/icons/hover_zoom_icon.png") 1x, url("../../../assets/images/icons/hover_zoom_icon.png") 2x), crosshair;
}

.details-single__title {
    margin-bottom: 5px;
    word-wrap: break-word;
    font-size: var(--big-font-size);
    font-family: Lato-Regular;
    letter-spacing: 0.0;
    line-height: 1.5;
    color: #000;
}

.details_star_rating {
    display: flex;
    align-items: center;
    gap: .3rem;
    margin-bottom: 10px;
}

.details_star_rating i {
    font-size: var(--font-size);
    color: #ffa900;
}

.details_star_rating span {
    color: #000;
    font-size: var(--font-size);
    line-height: 1.4;
    font-family: Lato-Regular;
}

.details_all_pricing {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-bottom: 25px;
    margin-bottom: 20px;
    border-bottom: 1px solid #eee;
}

.details_all_pricing_prices {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 1rem;
}

.details_all_pricing_prices span.prices_final-price {
    font-weight: 700;
    font-size: var(--big-font-size);
    color: inherit !important;
    cursor: pointer;
    color: #1C1B1B !important;
    letter-spacing: 0.025em;
}

.details_all_pricing_prices span.prices_main-price {
    color: inherit !important;
    cursor: pointer;
    font-size: var(--font-size);
    text-decoration: line-through;
    letter-spacing: 0.025em;
    line-height: 1.5;
}

.details_all_pricing_prices span.prices_discount {
    cursor: pointer;
    font-size: var(--font-size);
    color: #fff;
    background: #DC747D;
    padding: 2px 5px;
    letter-spacing: 0.025em;
    line-height: 1.5;
}

.details_all_pricing_savings {
    color: #ff4e4e;
    white-space: nowrap;
}

.details_all_pricing_savings .details_all_pricing_saving_price {
    cursor: pointer;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.5;
}

.details_all_pricing_savings .details_all_pricing_saving_price b {
    font-family: var(--body-font-bold);
}

.details_all_pricing_savings .text-info-label {
    font-size: var(--a-font-size);
    color: #000;
}

.details_details_sizes {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.details_details_sizes_chart {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 10px;
}

.details_details_sizes_chart p {
    text-transform: uppercase;
    font-weight: 500;
    letter-spacing: .3em;
    font-size: var(--a-font-size);
    cursor: default;
    line-height: 1.5;
}

.details_details_sizes_chart .size_chart-btn {
    padding: 5px 10px;
    color: #DC747D;
    cursor: pointer;
    font-weight: 700;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
    font-family: var(--body-font-bold);
}

.details_details_sizes_chart .size_chart-btn i {
    color: #DC747D;
    cursor: pointer;
    font-weight: 700;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
}

.details_sizes_radio {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: .5rem;
    margin-bottom: 10px;
}

.wishlist_and_add_cart-btn {
    display: flex;
    align-items: center;
    margin-bottom: 25px;
}

.wishlist_fav_btn {
    flex: 8%;
}

.wishlist_fav_btn a {
    border: 1px solid #dfdfdf;
    padding: 10px;
    margin-right: 2px;
    border-radius: 5px;
    transition: opcity .15s ease-in-out;
    display: flex;
    width: fit-content;
    color: var(--first-color);
}
.wishlist_fav_btn a i{
    font-size: 20px;
}

.wishlist_fav_btn img {
    display: inline-flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.add_to_cart_btn {
    flex: 92%;
}

.details_all_details .add_to_cart_btn .btn {
    width: 100%;
}

.details_sales_points {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
    margin-bottom: 25px;
}

.details_sales_point {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.details_sales_point img {
    width: 25px;
    height: 25px;
}

.details_sales_point .globalIcon {
    width: 21px;
    height: 21px;
    margin: 3px;
}

.details_sales_point .icon {
    position: relative;
    width: 25px;
    height: 25px;
}

.icon--inventory:before {
    animation: inventory-pulse 2s linear infinite;
}

@keyframes inventory-pulse {
    0% {
        opacity: .5;
    }

    100% {
        transform: scale(2.5);
        opacity: 0;
    }
}

.icon--inventory:after,
.icon--inventory:before {
    width: 9px;
    height: 9px;
    background: #54c63a;
    border-radius: 9px;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    margin: 8px;
}

.icon--inventory:after,
.icon--inventory:before {
    width: 9px;
    height: 9px;
    background: #54c63a;
    border-radius: 9px;
    position: absolute;
    left: 0;
    top: 0;
    content: "";
    margin: 8px;
}


.specifications {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.specifications .specifications_heading h3 {
    text-transform: uppercase;
    font-family: Lato-Regular;
    font-size: var(--font-size);
    letter-spacing: 0.0;
    line-height: 1.8;
    color: #000;
}

.details_other_info {
    margin-bottom: 25px;
}

.details_social_media_links {
    margin-top: 25px;
    margin-bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

.details_social_media_links a {
    color: #000;
    font-size: var(--font-size);
    display: inline-block;
    vertical-align: middle;
    letter-spacing: 0.025em;
    line-height: 1.4;
}

.details_social_media_links i {
    margin-right: .5rem;
    font-size: var(--font-size);
}


@media (max-width: 1280px) {
    .wishlist_and_add_cart-btn .wishlist_fav_btn {
        flex: 10%;
    }

    .wishlist_and_add_cart-btn .add_to_cart_btn {
        flex: 90%;
    }
}

@media (max-width: 1024px) {
    .quick_view__items {
        gap: 15px;
    }

    .wishlist_and_add_cart-btn .wishlist_fav_btn {
        flex: 11%;
    }

    .wishlist_and_add_cart-btn .add_to_cart_btn {
        flex: 89%;
    }
}

@media (max-width: 820px) {
    .quick_view__items {
        gap: 15px;
    }

    .desktop_image_grid {
        display: none;
    }

    .mobile_image_grid {
        display: grid;
    }

    .wishlist_and_add_cart-btn .wishlist_fav_btn {
        flex: 15%;
    }

    .wishlist_and_add_cart-btn .add_to_cart_btn {
        flex: 85%;
    }
}

@media (max-width: 769px) {

    .details_product_container {
        grid-template-columns: repeat(1, 1fr);
    }

    .quick_view-sliding-drawer-container {
        right: -350px;
        width: auto;
    }

    .details_all_details {
        margin-top: 25px;
        padding-left: 0px;
        align-items: center;
    }


    .details-single__title {
        font-size: 18px;
        text-align: center;
    }

    .details_star_rating {
        justify-content: center;
    }

    .details_all_pricing_prices {
        justify-content: center;
    }

    .details_all_pricing_savings {
        text-align: center;
    }

    .details_details_sizes {
        justify-content: center;
        align-items: center;
    }

    .details_sizes_radio {
        justify-content: center;
    }

    .details_sales_points {
        align-items: center;
    }

    .wishlist_and_add_cart-btn {
        width: 100%;
        position: fixed;
        right: 0;
        left: 0;
        bottom: 2.2rem;
        z-index: 100;
        background-color: #fff;
        padding-left: .5rem;
        padding-right: .5rem;
    }

    .wishlist_and_add_cart-btn {
        padding-top: 10px;
        padding-bottom: 10px;
        border: none;
    }

    .wishlist_and_add_cart-btn .wishlist_fav_btn {
        flex: 7%;
    }

    .wishlist_and_add_cart-btn .add_to_cart_btn {
        flex: 94%;
    }
}

@media (max-width: 640px) {
    .wishlist_and_add_cart-btn .wishlist_fav_btn {
        flex: 8%;
    }

    .wishlist_and_add_cart-btn .add_to_cart_btn {
        flex: 93%;
    }
}

@media (max-width: 475px) {
    .wishlist_and_add_cart-btn .wishlist_fav_btn {
        flex: 12%;
    }

    .wishlist_and_add_cart-btn .add_to_cart_btn {
        flex: 88%;
    }
}

.details_image_grid .image-grid-imageContainer video {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    object-fit: cover;
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    will-change: transform;
}

