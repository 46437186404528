.categories_banners_home {
    margin: 0 0 0 -22px;
}

.categories_banners_home .categories_banners_home_product_list {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    align-items: center;
}

.categories_banners_home .cat_banners {
    margin-bottom: 30px;
    padding-left: 22px;
    
}

@media (max-width: 769px) {
    .categories_banners_home .categories_banners_home_product_list {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        overflow: hidden;
        overflow-x: scroll;
    }

    .categories_banners_home .categories_banners_home_product_list .cat_banners {
        margin-bottom: 2px;
        width: auto;
    }

    .categories_banners_home .categories_banners_home_product_list .cat_banners .cat_banners-card {
        font-size: .75rem;
        width: 39vw;
        flex: 0 0 39vw;
        display: inline-block;
        float: none;
        white-space: normal;
    }
}