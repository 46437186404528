.mobile_image_sliders {
    width: 100%;
    height: 100%;
}

.mobile_image_sliders .swiper-slide {
    text-align: center;
    font-size: var(--font-size);
    background: #fff;

    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.mobile_image_sliders .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
}


.mobile_image_sliders .swiper-horizontal>.swiper-pagination-bullets.swiper-pagination-bullets-dynamic,
.mobile_image_sliders .swiper-pagination-horizontal.swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
    margin-bottom: 1%;
}


.mobile_image_sliders .swiper-pagination-bullet {
    background: #DEB4B4 !important;
    opacity: 1 !important;
}

.mobile_image_sliders .swiper-pagination-bullet-active {
    background-color: #fff !important;
}

.product_details_slider_img {
    width: 100%;
}

@media (max-width: 769px) {
    .product_details_slider_img {
        width: 100%;
    }
}