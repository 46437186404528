.section_heading_container {
    text-align: center;
    margin-bottom: 50px;
}

@media (max-width: 769px) {
    .section_heading_container {
        margin-right: -17px;
    }
}

.section_heading_container .section_heading_title {
    text-transform: uppercase;
    font-size: var(--big-font-size);
    line-height: 1.8;
    font-weight: 400;
    font-family: Lato-Regular;
}

.section_heading_container .section_heading_paragraph {
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
}

.sub_collection_section_heading_container {
    text-align: center;
    margin-bottom: 20px;
}

.sub_collection_section_heading_container .sub_collection_section_heading_title {
    text-transform: uppercase;
    font-size: var(--big-font-size);
    line-height: 1.8;
    font-weight: 400;
    color: #000000;
    font-family: Lato-Regular;
}

.sub_collection_section_heading_container .sub_collection_section_heading_paragraph {
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
}



.sub_collection_section_heading_container .top-short-desc {
    height: 20px;
    overflow: hidden;
    position: relative;
    max-width: 700px;
    margin: 0 auto;
}

.sub_collection_section_heading_container .top-short-desc.active {
    height: auto;
    overflow: inherit;
}

.sub_collection_section_heading_container .top-short-desc p {
    margin: 0;
}

.sub_collection_section_heading_container .top-short-desc strong {
    position: absolute;
    top: -8px;
    right: 0;
    background: #fff;
    padding: 5px;
    z-index: 1;
}

.sub_collection_section_heading_container .top-short-desc strong:before {
    content: "+";
    font-size: 20px;
    color: #DC747D;
    cursor: pointer;
}

.top-short-desc.active strong {
    position: inherit;
    display: block;
}

.top-short-desc.active strong:before {
    content: "-";
}