.watch-and-shop-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-shadow: 0 10px 60px 0 #0000001a;
    border-radius: 10px;
}

.watch-and-shop-item video {
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.watch-and-shop-items .swiper-button-disabled {
    pointer-events: unset !important;
}

.watch-and-shop-items .swiper-button-next,
.watch-and-shop-items .swiper-rtl .swiper-button-prev {
    padding: 2rem !important;
    background-color: #00000082 !important;
    border-radius: 5px !important;
    right: 0 !important;
}

.watch-and-shop-items .swiper-button-next:after,
.watch-and-shop-items .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    color: white;
    font-size: 1.5rem;
}

.watch-and-shop-items .swiper-button-prev,
.watch-and-shop-items .swiper-rtl .swiper-button-next {
    padding: 2rem !important;
    background-color: #00000082 !important;
    border-radius: 5px !important;
    left: 0 !important;
}

.watch-and-shop-items .swiper-button-prev:after,
.watch-and-shop-items .swiper-rtl .swiper-button-next:after {
    content: 'prev';
    color: white;
    font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
    .watch-and-shop .section_heading_container {
        margin-right: 0;
    }

    .watch-and-shop .occasion-items {
        grid-template-columns: repeat(2, 1fr);
    }

    .watch-and-shop-items .swiper-button-next,
    .watch-and-shop-items .swiper-rtl .swiper-button-prev {
        padding: 1rem !important;
    }

    .watch-and-shop-items .swiper-button-next:after,
    .watch-and-shop-items .swiper-rtl .swiper-button-prev:after {
        font-size: 1rem;
    }

    .watch-and-shop-items .swiper-button-prev,
    .watch-and-shop-items .swiper-rtl .swiper-button-next {
        padding: 1rem !important;
    }

    .watch-and-shop-items .swiper-button-prev:after,
    .watch-and-shop-items .swiper-rtl .swiper-button-next:after {
        font-size: 1rem;
    }
}