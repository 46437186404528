.size_radio_btn input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.size_radio_btn label {
    display: inline-block;
    background-color: #ffffff;
    padding: 7px 15px;
    font-family: Lato-Regular;
    font-size: var(--font-size);
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    letter-spacing: 0.025em;
    line-height: 1.4;
}

.size_radio_btn input[type="radio"]:checked+label {
    background-color: #DC747D;
    border: 1px solid #DC747D;
    box-shadow: 0 0 0 2px #DC747D;
    color: #fff;
}


.size_radio_btn label:hover {
    cursor: pointer;
    background-color: rgb(255, 255, 255);
}

.size_radio_btn-disabled input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.size_radio_btn-disabled label {
    display: inline-block;
    background-color: #e9e9e9;
    padding: 7px 15px;
    font-family: Lato-Regular;
    font-size: var(--font-size);
    border: 1px solid #c1c1c1;
    border-radius: 5px;
    letter-spacing: 0.025em;
    line-height: 1.4;
}

.size_radio_btn-disabled input[type="radio"]:checked+label {
    background-color: #DC747D;
    border: 1px solid #DC747D;
    box-shadow: 0 0 0 2px #DC747D;
    color: #fff;
}


.size_radio_btn-disabled label:hover {
    cursor: default;
    background-color: #e9e9e9;
}