.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    display: none;
    z-index: 10000;
    overflow: hidden !important;
}

.filter-drawer-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.overlay.show {
    display: block;
}

.filter-sliding-drawer-container {
    position: fixed;
    top: 0;
    left: -450px;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    width: 450px;
    height: 100%;
    background-color: #fff;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
    transition: left 0.3s ease-in-out;
    z-index: 10000;
}

.filter-sliding-drawer-container.open {
    left: 0;
    /* Slide in when open */
}

.filter-drawer-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #efefef;
    padding: 1rem 1rem;
}

.filter-drawer-header h2 {
    font-family: Lato-Regular;
    font-size: 1.2rem;
    text-transform: uppercase;
    letter-spacing: 0em;
    line-height: 1.8;
    color: #000;
}

.filter-drawer-header i {
    font-size: 1.5rem;
    cursor: pointer;
}

/* .filter-drawer__inner {
    max-height: 100%;
    overflow-y: auto; */
/* } */

.filter-drawer__inner,
.filter-drawer__scrollable {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.filter-drawer__scrollable {
    padding-top: 20px;
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.filter-drawer__footer,
.filter-drawer__scrollable {
    padding-left: 20px;
    padding-right: 20px;
}

@media (max-width: 769px) {
    .filter-sliding-drawer-container {
        left: -270px;
        width: 270px;
    }

    .filter-drawer__scrollable {
        padding-top: 0px;
    }

    .filter-drawer__footer,
    .filter-drawer__scrollable {
        padding-right: 0px;
    }
}