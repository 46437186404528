footer {
    overflow: hidden;
    left: 0;
    bottom: 0;
    width: 100%;
}

.site-footer {
    padding-top: 20px;
    padding-bottom: 30%;
    background-color: #fdeee7;
    background-color: #fdeee7;
    color: #000;
    color: #000000;
    background-image: url(../../assets/images/Fab-Galaxy-Footer-Banner.jpg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: bottom;
}

.collapsible {
    max-height: 1000px;
    overflow: hidden;
    transition: max-height 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.collapsible_list {
    list-style: none;
    padding: 0;
    margin: 0;
}

.footer-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
}

.footer__small-text {
    font-size: var(--a-font-size);
    padding: 7.5px 0;
    margin: 0;
    text-align: center;
}

.grid__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: .5rem;
}

.footer__title {
    font-size: .8rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .30rem;
    color: #DC747D;
    margin-bottom: 10px;
}

.footer__title-btn {
    font-size: .8rem;
    font-weight: 400;
    text-transform: uppercase;
    letter-spacing: .30rem;
    color: #000000;
    padding: 15px 10px 15px 0;
    outline: none;
    -webkit-tap-highlight-color: transparent;
}

.footer__title-btn {
    display: none;
}

.collapsible_list {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.collapsible_links a {
    font-size: var(--a-font-size);
    color: #000000;
}


@media (max-width: 960px) {
    .footer-grid {
        grid-template-columns: repeat(2, 1fr);
        align-items: flex-start;
        gap: 2.5rem;
    }
}

@media (max-width: 768px) {
    footer {
        text-align: center;
    }

    .footer-grid {
        grid-template-columns: repeat(1, 1fr);
        align-items: center;
        justify-content: center;
        gap: .2rem;
    }

    .grid__item {
        align-items: center;
    }

    .grid__item {
        border-bottom: 1px solid rgba(0, 0, 0, .12);
    }

    .footer__title {
        display: none;
    }

    .footer__title-btn {
        position: relative;
        display: block;
        width: 100%;
    }


    .footer__title-btn img {
        position: absolute;
        right: 0;
        height: 12px;
        width: 12px;
    }

    .collapsible {
        max-height: 0px;
        /* Set a value larger than the expected maximum height */
        transition: max-height 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94);
        /* Adjust timing function as needed */
    }

    .collapsible.active {
        max-height: 100vh;
        /* Set to the maximum height you want for mobile screens */
    }

    .collapsible .collapsible_list {
        padding-bottom: 1.5rem;
    }
}