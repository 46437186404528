.header-wrapper {
    background-color: #FBFBFB;
}

.upper_header {
    padding-top: 1rem;
    padding-bottom: 0rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.site-nav__icons .site-nav__link svg {
    fill: none !important;
    stroke-width: 2px;
}

.cart-has-item {
    position: relative;
}

.cart-has-item .red-dot-with-text{
    position: absolute;
    top: 0px;
    right: -5px;
    background-color: red;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--body-font-bold);
    font-size: 12px;
    color: white;
}

.wishlist-has-item {
    position: relative;
}

.wishlist-has-item .red-dot{
    position: absolute;
    top: 9px;
    right: 1px;
    background-color: red;
    height: 8px;
    width: 8px;
    border-radius: 50%;
}

.link_option_display {
    display: none !important;
}

.link_option_hidden {
    display: block;
}

.menu_trigger {
    display: none;
}

.menu_trigger a {
    cursor: pointer;
}

.menu_trigger a img {

    height: 25px;
    width: auto;
}

@media (max-width: 769px) {
    .link_option_hidden {
        display: none;
    }

    .link_option_display {
        display: block;
    }

}


/* /// */

.search-container {
    order: 1;
    width: 25%;
    position: relative;
    display: inline-block;
}

.logo_area {
    order: 2;
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.company_logo a img{
    height: 6rem;
width: auto;
}

.buttons_area {
    order: 4;
    width: 25%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.buttons_area .site-nav__icons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.buttons_area .site-nav__icons a {
    padding-top: 7.5px;
}

.buttons_area .site-nav__icons a img {
    height: 28px;
    width: 28px;
}

.search-container .search-icon i {
    cursor: pointer;
    position: absolute;
    z-index: 99999;
    margin: 5px 5px;
    height: 35px;
    font-size: 1.3rem;
    z-index: 0;
}

.clear-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    display: none;
}

.search-input {
    border: 1px solid #f5f5f5 !important;
    padding-left: 40px !important;
    width: 100%;
    height: 35px !important;
    font-size: 16px !important;
    font-weight: 300;
    background-color: #f5f5f5;
    background-size: 17px !important;
    background-position-x: 10px !important;
    background-position-y: center !important;
    background-repeat: no-repeat !important;
    color: #000000;
    line-height: normal;
    padding: 5px 20px 5px 14px;
}

.search-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0 5px 20px 0 rgba(143, 149, 154, .6);
    display: none;
    z-index: 1;
    padding: 15px 15px 15px;
}

.search-dropdown .search_heading {
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 15px;
    margin-bottom: 6px;
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: .5px;
}

.search-dropdown .search_heading span {
    color: #DC747D;
    font-size: 15px;
    font-weight: 600;
}

.search-dropdown .search_heading img {
    height: 20px;
    width: 20px;
}

.search-dropdown .search_links a {
    text-decoration: none;
    color: #333;
    transition: background-color 0.3s;
    padding: 5px 0px;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: var(--a-font-size);
    text-transform: capitalize;
    font-weight: 600;
    letter-spacing: .5px;
}

.search-dropdown .search_links img {
    height: 20px;
    width: 20px;
}


.search-dropdown .search_links a:hover {
    cursor: pointer;
    font-weight: bold;
}

.search-container:focus-within .search-dropdown,
.search-input:valid+.clear-button {
    display: block;
}


/* header */
.header {
    display: block;
    width: 100%;
    position: relative;
    box-shadow: -2px 3px 4px rgba(0, 0, 0, 0.01);
}

.header .item-left {
    flex: 0 0 17%;
}

.header .logo a {
    font-size: 30px;
    color: #000000;
    font-weight: 700;
    text-decoration: none;
}

.header .item-center {
    flex: 0 0 66%;
}


.header .menu .menu-main {
    text-align: center;
}

.header .item-right {
    flex: 0 0 17%;
    display: flex;
    justify-content: flex-end;
}

.header .item-right a {
    text-decoration: none;
    font-size: var(--a-font-size);
    color: #000000;
    display: inline-block;
    margin-left: 10px;
    transition: color 0.3s ease;
}

.sale_gif {
    height: 28px;
    margin-bottom: -5px;
}

.header .menu>ul>li {
    display: inline-block;
    line-height: 50px;
}

.header .menu>ul>li>a {
    font-size: var(--a-font-size);
    font-weight: 500;
    color: #000000;
    position: relative;
    text-transform: uppercase;
    letter-spacing: 0.2em;
    transition: color 0.3s ease;
    cursor: pointer;
    padding: 7px 9px;
}

.header .menu>ul>li .sub-menu {
    position: absolute;
    z-index: 500;
    background-color: #ffffff;
    box-shadow: 0 10px 20px #00000017;
    padding: 5px 0 5px;
    transition: all 0.5s ease;
    margin-top: 25px;
    opacity: 0;
    visibility: hidden;
}

@media(min-width: 769px) {
    .header .menu>ul>li.menu-item-has-children:hover .sub-menu {
        margin-top: 0;
        visibility: visible;
        opacity: 1;
    }

    .header .menu>ul>li>a>i {
        display: none;
    }

}

.header .menu>ul>li .sub-menu>ul>li {
    line-height: 1;
}

.header .menu>ul>li .sub-menu>ul>li>a {
    display: inline-block;
    padding: 8px 15px;
    font-size: 15px;
    color: #000000;
    transition: color 0.3s ease;
    text-decoration: none;
    text-transform: capitalize;
}

.header .menu>ul>li .single-column-menu {
    /* min-width: 280px;
    max-width: 350px; */
    min-width: 140px;
    max-width: fit-content;
}

.header .menu>ul>li .sub-menu.mega-menu>.list-item>ul>li {
    line-height: 1;
    display: block;
}

.header .menu>ul>li .sub-menu.mega-menu>.list-item>ul>li>a {
    padding: 8px 15px;
    display: inline-block;
    font-size: 20px;
    color: #000000;
    transition: color 0.3s ease;
}

.sub-menu .list-item ul li {
    line-height: 2;
    text-align: left;
}

.sub-menu .list-item ul li a {
    padding: 7.5px 10px;
    display: block;
    width: 100%;
    line-height: 1.2;
    color: #000000;
    text-transform: capitalize;
    font-size: var(--a-font-size);
}

.sub-menu .list-item ul li a:hover {
    color: var(--first-color);
}

.list-item-link {
    padding: 8px 15px;
}

.header .menu>ul>li .sub-menu.mega-menu {
    left: 50%;
    transform: translateX(-50%);
}

.header .menu>ul>li .sub-menu.mega-menu-column-4 {
    max-width: 1100px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    padding: 20px 15px;
}

.header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item {
    flex: 0 0 25%;
    padding: 0 15px;
}

.header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item .title {
    font-size: var(--font-size);
    color: #000000;
    font-weight: 500;
    line-height: 1;
    padding: 10px 0;
}

.header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item.text-center .title {
    text-align: center;
}

.header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item img {
    max-width: 100%;
    width: 100%;
    vertical-align: middle;
    margin-top: 10px;
}

.header .menu>ul>li .sub-menu.mega-menu>.list-item>ul>li>a:hover,
.header .menu>ul>li .sub-menu>ul>li>a:hover,
.header .item-right a:hover,
.header .menu>ul>li:hover>a {
    color: var(--first-color);
}

.mobile-menu-head,
.mobile-menu-trigger {
    display: none;
}

/*responsive*/
@media(max-width: 769px) {
    .menu_trigger {
        display: block;
        width: 25%;
    }

    .search-container {
        order: 4;
        width: 100%;
        border-top: 1px solid #F8F8F8;
        padding: 5px 0px 0px 0px;
        margin-top: 10px;
    }

    .upper_header {
        padding-bottom: 0.7rem;
    }

    .logo_area {
        order: 2;
        width: 50%;
    }
    
    .company_logo a img {
        height: 4.5rem;
    }

    .buttons_area {
        order: 3;
        width: 25%;
    }

    .header .item-center {
        order: 3;
        flex: 0 0 100%;
    }

    .header .item-right {
        flex: 0 0 auto;
    }

    .v-center {
        justify-content: space-between;
    }

    .header .mobile-menu-trigger {
        display: flex;
        height: 30px;
        width: 30px;
        margin-left: 15px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
    }

    .header .mobile-menu-trigger span {
        display: block;
        height: 2px;
        background-color: #000000;
        width: 24px;
        position: relative;
    }

    .header .mobile-menu-trigger span:before,
    .header .mobile-menu-trigger span:after {
        content: '';
        position: absolute;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000000;
    }

    .header .mobile-menu-trigger span:before {
        top: -6px;
    }

    .header .mobile-menu-trigger span:after {
        top: 6px;
    }

    .header .item-right {
        align-items: center;
    }

    .header .menu {
        position: fixed;
        width: 320px;
        background-color: #ffffff;
        left: 0;
        top: 0;
        height: 100%;
        overflow: hidden;
        transform: translate(-100%);
        transition: all 0.5s ease;
        z-index: 1099;
    }

    .header .menu.active {
        transform: translate(0%);
    }

    .header .menu>ul>li {
        line-height: 1;
        margin: 0;
        display: block;
    }

    .header .menu>ul>li>a {
        line-height: 50px;
        height: 50px;
        padding: 0 50px 0 15px;
        display: block;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }

    .header .menu>ul>li>a i {
        position: absolute;
        height: 50px;
        width: 50px;
        top: 0;
        right: 0;
        text-align: center;
        line-height: 50px;
        transform: rotate(-90deg);
        border-top: 1px solid rgba(0, 0, 0, 0.1);
    }

    .header .menu .mobile-menu-head {
        display: flex;
        height: 50px;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 501;
        position: sticky;
        background-color: #ffffff;
        top: 0;
    }

    .header .menu .mobile-menu-head .go-back {
        height: 50px;
        width: 50px;
        border-right: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;
        line-height: 50px;
        text-align: center;
        color: #000000;
        font-size: var(--font-size);
        display: none;
    }

    .header .menu .mobile-menu-head.active .go-back {
        display: block;
    }

    .header .menu .mobile-menu-head .current-menu-title {
        font-size: 15px;
        font-weight: 500;
        color: #000000;
    }

    .header .menu .mobile-menu-head .mobile-menu-close {
        height: 50px;
        width: 50px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        cursor: pointer;
        line-height: 50px;
        text-align: center;
        color: #000000;
        font-size: var(--big-font-size);
    }

    .header .menu .menu-main {
        height: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        text-align: start;
    }

    .header .menu>ul>li .sub-menu.mega-menu,
    .header .menu>ul>li .sub-menu {
        visibility: visible;
        opacity: 1;
        position: absolute;
        box-shadow: none;
        margin: 0;
        padding: 15px;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        padding-top: 65px;
        max-width: none;
        min-width: auto;
        display: none;
        transform: translateX(0%);
        overflow-y: auto;
    }

    .header .menu>ul>li .sub-menu.active {
        display: block;
    }

    @keyframes slideLeft {
        0% {
            opacity: 0;
            transform: translateX(100%);
        }

        100% {
            opacity: 1;
            transform: translateX(0%);
        }
    }

    @keyframes slideRight {
        0% {
            opacity: 1;
            transform: translateX(0%);
        }

        100% {
            opacity: 0;
            transform: translateX(100%);
        }
    }

    .header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item img {
        margin-top: 0;
    }

    .header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item.text-center .title {
        margin-bottom: 20px;
    }

    .header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item.text-center:last-child .title {
        margin-bottom: 0px;
    }

    .header .menu>ul>li .sub-menu.mega-menu-column-4>.list-item {
        flex: 0 0 100%;
        padding: 0px;
    }

    .header .menu>ul>li .sub-menu>ul>li>a,
    .header .menu>ul>li .sub-menu.mega-menu>.list-item>ul>li>a {
        display: block;
    }

    .header .menu>ul>li .sub-menu.mega-menu>.list-item>ul {
        margin-bottom: 15px;
    }

    .menu-overlay {
        position: fixed;
        background-color: rgba(0, 0, 0, 0.5);
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1098;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease;
    }

    .menu-overlay.active {
        visibility: visible;
        opacity: 1;
    }
}

.hide-search{
    display: none !important;
}