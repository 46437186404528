.product-global-modal .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    display: none;
    z-index: 500;
    overflow: hidden !important;
}

.global_modal-drawer-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.overlay.show {
    display: block;
}

.global_modal-sliding-drawer-container {
    position: fixed;
    top: 0;
    top: -4500px;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
    transition: fade 0.3s ease;
    z-index: 501;
}

.global_modal-sliding-drawer-container.open {
    right: 35%;
    left: 35%;
    bottom: 40%;
    top: 40%;
    padding: 0.5rem;
    /* Slide in when open */
}


.global_modal-drawer-header {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
}

.global_modal-drawer-header i {
    font-size: 1.5rem;
    cursor: pointer;
    align-self: flex-end;
    padding: 12px;
}

/* .global_modal-drawer__inner {
    max-height: 100%;
    overflow-y: auto; */
/* } */

.global_modal-drawer__inner,
.global_modal-drawer__scrollable {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    align-items: center;
    justify-content: center;
}

.global_modal-drawer__scrollable {
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}

.global_modal_content_area .title{
    text-align: center;
    font-size: var(--font-size);
    text-transform: uppercase;
}

.global_modal-drawer__scrollable {
    padding-left: 0px;
    padding-right: 0px;
}

.button-container{
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.confirm-btn {
    width: fit-content !important;
    margin-left: 10px;
    margin-right: 10px;
}


@media (max-width: 950px) {
    .global_modal-sliding-drawer-container.open {
        right: 10%;
        left: 10%;
       
        /* Slide in when open */
    }
}

@media (max-width: 1024px) {
    .quick_view__items {
        gap: 15px;
    }
}

@media (max-width: 769px) {

    .quick_view__items {
        grid-template-columns: repeat(1, 1fr);
        gap: 30px;
    }

    .quick_view-sliding-drawer-container {
        right: -350px;
        width: auto;
    }

    .product_all_details {
        width: 100%;
    }
}

@media (max-width: 640px) {
    .global_modal-sliding-drawer-container.open {
        right: 5%;
        left: 5%;
       
    }
}