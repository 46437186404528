.faq_list {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1rem;
}

.faq_list .product_collapsible-list {
    width: 80%;
}

.faq_list .product_collapsible-list .product_collapsible-list-btn {
    text-align: left;
}

@media screen and (max-width: 769px) {
    .faq_list .product_collapsible-list {
        width: 100%;
    }
}