.overlay-items {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.badge-tag p {
    margin: 10px 0 10px 10px;
    padding: 2px 5px;
    font-size: var(--a-font-size);;
    background: #DC747D;
    color: #fff;
}

.fav-btn-item {
    padding: 10px 10px 10px 0px;
}

.fav-btn-item button {
    background: #fff;
    border-radius: 100%;
    padding: 5px;
    line-height: 0px;
    height: 30px;
    width: 30px;
    text-align: center;
    vertical-align: middle;
}

.fav-btn-item button i {
    color: #DC747D;
}

.product-text-info {
    padding: 10px 0 6px;
    line-height: 1.3;
}

.product-text-info small {
    color: #848484;
    margin-bottom: 10px;
    font-size: 11px;
}

.product-text-info h2 {
    color: #000000;
    font-size: var(--font-size);
    text-transform: capitalize;
    letter-spacing: .07em;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product_price {
    display: flex;
    flex-direction: column;
}

.product-final-price {
    font-family: var(--body-font-bold);
    font-size: var(--font-size);
    font-weight: 700;
    color: #1C1B1B;

    margin-top: 5px;
}

.product-final-price .product-original-price {
    text-decoration: line-through;
    margin-right: 5px;
    margin-top: 5px;
    margin-left: 5px;
    font-size: 15px;
    color: #000000;
}

.product-final-price .product-original-price .original-price {
    font-family: Lato-Regular;
    font-size: var(--a-font-size);;
    color: #848484 !important;
}

.sale_percentage {
    color: #DC747D;
    white-space: nowrap;
    font-family: var(--body-font-bold);
    display: block;
    font-size: 17px;
    margin-top: 5px;
}

.product_images {
    position: relative;
    overflow: hidden;
    height: fit-content;
}

.product_images img {
    height: 100%;
    width: 100%;
    /* Change 'auto' to '100%' */
    object-fit: cover;
}

.product-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: opacity 0.5s ease;
}

.transition {
    transition: transform 0.5s;
}

.quick-view {
    position: absolute;
    bottom: 10px;
    right: 10px;
    left: 10px;
    z-index: 2;
    padding: 8px 5px;
    text-align: center;
    cursor: pointer;
    opacity: 0;
    transform: translateY(5px);
    transition: opacity .25s ease, transform .25s ease-out, background .4s ease;
}

.quick-view .quick-view-btn {
    background-color: #DC747D;
    color: #fff;
    overflow: hidden;
    padding: 8px 5px;
    width: 100%;
    font-size: var(--font-size);
    font-family: Lato-Regular;
}

.product-card:hover .quick-view {
    opacity: 1;
    transform: translateY(0);
    transition: opacity .25s ease, transform .25s ease-out;
}

@media (max-width: 769px) {
    .fav-btn-item {
        padding: 10px 25px 10px 0px;
    }
}

@media (max-width: 640px) {
    .fav-btn-item {
        padding: 10px 10px 10px 0px;
    }
}