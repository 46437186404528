.mobile_filter_buttons_container {
    display: none;
    z-index: 5;
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    height: 3.5rem;
    justify-content: space-between;
    align-items: center;
    background: #fff;
    -webkit-box-shadow: 0px 8px 5px 4px rgba(0, 0, 0, 0.07);
    -moz-box-shadow: 0px 8px 5px 4px rgba(0, 0, 0, 0.07);
    box-shadow: 0 8px 5px 4px rgba(0, 0, 0, 0.07);
}

.mobile_filter_buttons_left,
.mobile_filter_buttons_right {
    flex: 0 1 50%;
}

.mobile_filter_buttons_left .mobile_filter_button,
.mobile_filter_buttons_right .mobile_filter_button {
    width: 100%;
    height: 3.5rem;
    color: #000;
    font-size: 1.3rem;
    padding-top: .5rem;
}

.mobile_filter_buttons_right .mobile_filter_featured_select {
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile_filter_buttons_right .mobile_filter_featured_select select {
    font-size: 1.3rem;
    color: #000;
    width: 100%;
    height: 3.5rem;
    border: 0;
    border-left: 1px solid #e3dcdc;
    background: #fff;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    text-align-last: center;
    padding-top: .5rem;
}

.mobile_filter_buttons_right .mobile_filter_featured_select select:focus-visible {
    outline: none;
}

@media only screen and (max-width: 769px) {
    .mobile_filter_buttons_container {
        display: flex;
    }
}