

.title-footer {
    min-width: 135px;
}


.box-pre-footer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 1rem;
}

.title-footer h4{
    text-transform: uppercase;
    font-size: var(--big-font-size);
    line-height: 1.8;
    font-weight: 400;
    font-family: Lato-Regular; 
    color: var(--text-color);
}

.content-footer-pre a {
    color: #d97676;
    font-size: small;
}

.content-footer-pre {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: flex-start;
}

.seperator {
    margin-left: 10px;
    margin-right: 10px;
}

@media screen and (max-width: 769px) {
    .seperator {
        margin-left: 3px;
        margin-right: 3px;
    }
}