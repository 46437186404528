.product_list_main_container {
    list-style: none;
    padding: 0;
    margin: 0 0 0 -22px;
}

.product_filter_div {
    max-height: 100%;
    overflow-y: hidden;
    width: 20%;
    float: left;
    padding-left: 22px;
    min-height: 1px;
}

.collection-sidebar {
    padding-right: 10px;
    margin-bottom: 20px;
}

.tag--remove {
    position: relative;
}

.btn--small {
    position: relative;
    overflow: hidden;
    transition: background .2s ease 0s;
    line-height: 1.4;
    font-size: 12px;
    padding: 8px 8px;
    text-transform: uppercase;
    letter-spacing: .3em;
    cursor: pointer;
    border: 1px solid transparent;
    -webkit-user-select: none;
    user-select: none;
    -webkit-appearance: none;
    border-radius: 3px;
    color: #fff;
    background: #DC747D;
}

.tag--remove a {
    text-align: left;
    margin-bottom: 5px;
}

.tag-list a,
.tag-list label {
    cursor: pointer;
    display: block;
    line-height: 1.4;
}

.tag--remove .icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
    color: #fff;
    color: var(--colorBtnPrimaryText);
}

.tag-list--active-tags:empty {
    display: none;
}

.no-bullets {
    list-style: none outside;
    margin-left: 0;
}

.product_list {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.product_list .products {
    margin-bottom: 30px;
    padding-left: 22px;
    width: 33.33333%;
}
.load-more-div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.load-more-btn{
    color: #DC747D;
    font-size: 20px;
}


/* lg */
@media (max-width: 1024px) {
    .product_filter_div {
        width: 25%;
    }

    .product_list .products {
        padding-left: 1rem;
    }
}

@media (max-width: 769px) {
    .product_filter_div {
        display: none;
    }

    .product_list {
        justify-content: flex-start;
        gap: 2px;
        margin-left: -15px;
        margin-right: -22px;
    }

    .product_list_main_container {
        margin: 0px;
    }

    .product_list .products {
        width: 49%;
        padding-left: 0px;
        margin-bottom: 15px;
    }

    .product_list .products .product-card {
        font-size: .75rem;
        width: 100%;
        display: inline-block;
        float: none;
        white-space: normal;
    }
}