.select-field {
    margin-bottom: 10px;
    width: 100%;
}

.select-field .select-field-label {
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: .3em;
    font-family: Lato-Regular;
    font-size: .9em;
}

.select-field .select-field-tag:focus {
    border: 1px solid #000;
}

.select-field .select-field-tag {
    margin-bottom: 5px;
    height: 44px;
    width: 100%;
    font-size: var(--font-size);
    background-color: inherit;
    color: inherit;
    background-position: 100%;
    background-image: url("../../assets/images/icons/down_arrow.svg");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 11px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 0.01px;
    text-align: left;
    cursor: pointer;
    border: 1px solid;
    border-color: #c1c1c1;
    max-width: 100%;
    padding: 8px 4vw 8px 10px;
    border-radius: 0;
}

.select-field .select-field-tag {
    outline: none;
}