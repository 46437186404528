.specifications_contents {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    margin: 0 0 10px 0;
    padding: 0;
    justify-content: space-between;

}

.specifications_contents .specifications_item {
    display: flex;
    flex-direction: column;
    gap: .2rem;
    margin: 0 0 12px;
    padding-bottom: 10px;
    width: 45%;
    border-bottom: 1px solid #eaeaec;
}

.specifications_contents .specifications_item .specifications_item_title {
    color: #938e8e;
    letter-spacing: 0.025em;
    line-height: 1.4;
    font-size: var(--font-size);
    text-transform: capitalize;
    font-family: Lato-Regular;
}

.specifications_contents .specifications_item .specifications_item_desc {
    color: #000;
    letter-spacing: 0.025em;
    line-height: 1.4;
    font-size: var(--font-size);
    text-transform: capitalize;
    font-family: Lato-Regular;
}