.top_sale_announcement {
    position: relative;
    top: 0;
    background: linear-gradient(180deg, #FBFBFB, #fff3eb);
    z-index: 10;
    display: flex !important;
    width: 100%;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 7px;
    column-gap: 26px;
    row-gap: 10px;
    border-bottom: #c5c8d1 solid 0px;
    cursor: pointer;
}

.announcement_grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
}

.announcement_inner_flex {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: -1rem;
    width: auto;
}

.colon {
    justify-content: flex-start;
}

.top_sale_announcement p {
    font-size: 1.5rem;
    font-weight: bold;
    color: #202223;
    letter-spacing: normal;
    text-transform: none;
    text-align: left;
}

.top_sale_announcement span {
    font-size: 10px;
}