.rating-drawer-content .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    /* Semi-transparent black */
    display: none;
    z-index: 500;
    overflow: hidden !important;
}

.rating-drawer-content {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.overlay.show {
    display: block;
}

.rating-sliding-drawer-container {
    position: fixed;
    
    top: 100%;
    overflow: hidden;
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    box-shadow: -5px 0 10px rgba(0, 0, 0, 0.1);
    transition: fade 0.3s ease;
    z-index: 501;
}

.rating-sliding-drawer-container.open {
    right: 30%;
    left: 30%;
    bottom: 15%;
    top: 15%;
    /* Slide in when open */
}

.rating-drawer__inner,
.rating-drawer__scrollable {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
}

.rating-drawer__scrollable {
    overflow: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}


.rating-drawer__scrollable {
    padding-left: 0px;
    padding-right: 0px;
}

.rating__items {
    padding-left: 20px;
    padding-right: 20px;
}

.header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
    border-bottom: 1px solid #ccc;
}

.close-button {
    color: red;
    font-weight: 900;
    font-size: larger;
}

.main-container {
    display: block;
    align-items: center;
    justify-content: center;
    padding: .5rem;
    margin-top: 1rem;
}

.image-cancel{
    width: 20%;
}


.question-label{
    margin-top: 0.9rem;
    margin-bottom: 1rem;
}

.btn-container{
    display: flex;
    justify-content: center;
    align-items: center;
}

.text-area-cancel{
    min-width: 100%;
    max-width: 100%;
    min-height: 100px;
    outline: none;
    border: 1px solid;
    border-color: #c1c1c1;
    padding: 8px 10px;
    border-radius: 0;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
    margin-bottom: 1rem;
}

.error-text-cancel{
    color: red;
}

.refund-text{
    color: orange;
    margin-top: 10px;
    margin-bottom: 10px;
}

.order_rating_items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}
.order_rating_items .rating_item_info {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}
.order_rating_items .rating_item_info .image-cancel{
    flex: 25%;
}
.order_rating_items .rating_item_info .rating-text-infos{
    flex: 75%;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.order_rating_items .rating_item_info .rating-text-infos h2{
    color: #000000;
    font-size: var(--font-size);
    text-transform: uppercase;
    letter-spacing: .07em;
    overflow: hidden;
    text-overflow: ellipsis;
}
.order_rating_items .question-label {
    width: 100%;
}
.order_rating_items label{
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: .3em;
    font-family: Lato-Regular;
    font-size: .9em;
}


@media (max-width: 1440px) {
   
}
@media (max-width: 1440px) {
    .rating-sliding-drawer-container.open {
        right: 25%;
        left: 25%;
    }
}
@media (max-width: 1280px) {
    .rating-sliding-drawer-container.open {
        right: 20%;
        left: 20%;
    }
}
@media (max-width: 1024px) {
    .rating-sliding-drawer-container.open {
        right: 18%;
        left: 18%;
    }
}
@media (max-width: 868px) {
    .rating-sliding-drawer-container.open {
        right: 16%;
        left: 16%;
    }
}
@media (max-width: 768px) {
    .rating-sliding-drawer-container.open {
        right: 10%;
        left: 10%;
        bottom: 10%;
        top: 10%;
    }
    .main-container {
        padding: 0;
    }
    
    .order_rating_items .rating_item_info .image-cancel{
        flex:50%;
    }
    .order_rating_items .rating_item_info .rating-text-infos{
        flex: 50%;
    }
    .order_rating_items .rating_item_info .rating-text-infos h2{
        font-size: 14px;
    }

    .order_rating_items label{
        font-size: .8em;
    }
}
@media (max-width: 640px) {
    .rating-sliding-drawer-container.open {
        right: 5%;
        left: 5%;
    }
}
@media (max-width: 475px) {
    .rating-sliding-drawer-container.open {
        right: 2%;
        left: 2%;
    }
}


