.customers-review-item {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    box-shadow: 0 10px 60px 0 #0000001a;
    border-radius: 10px;
}

.customers-review-item .customer-review-button {
    position: relative;
}

.customers-review-item .customer-review-info {
    position: absolute;
    background-color: #ffffffc1;
    padding: 1rem;
    bottom: 0;
}

.customer-review-info .review-stars {
    display: flex;
    align-items: center;
    gap: .3rem;
    margin-bottom: 10px;
}

.customer-review-info .review-stars i {
    font-size: 1.3rem;
    color: #ffa900;
}

.customer-review-info .review-stars span {
    color: #000;
    font-size: 1rem;
    line-height: 1.4;
    font-family: Lato-Regular;
    margin-top: 5px;
}

.customer-review-info .review-text p {
    color: #000;
    font-size: 1rem;
    line-height: 1.4;
    font-family: Lato-Regular;
    max-height: 4.9rem;
}

.customers-review-item img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
}

.customers-review-items .swiper-button-disabled {
    pointer-events: unset !important;
    display: none;
}

.customers-review-items .swiper-button-next,
.customers-review-items .swiper-rtl .swiper-button-prev {
    padding: 2rem !important;
    background-color: #00000082 !important;
    border-radius: 5px !important;
    right: 0 !important;
}

.customers-review-items .swiper-button-next:after,
.customers-review-items .swiper-rtl .swiper-button-prev:after {
    content: 'next';
    color: white;
    font-size: 1.5rem;
}

.customers-review-items .swiper-button-prev,
.customers-review-items .swiper-rtl .swiper-button-next {
    padding: 2rem !important;
    background-color: #00000082 !important;
    border-radius: 5px !important;
    left: 0 !important;
}

.customers-review-items .swiper-button-prev:after,
.customers-review-items .swiper-rtl .swiper-button-next:after {
    content: 'prev';
    color: white;
    font-size: 1.5rem;
}

@media screen and (max-width: 768px) {
    .customers-review .section_heading_container {
        margin-right: 0;
    }

    .customers-review .occasion-items {
        grid-template-columns: repeat(2, 1fr);
    }


    .customers-review-items .swiper-button-next,
    .customers-review-items .swiper-rtl .swiper-button-prev {
        padding: 1rem !important;
    }

    .customers-review-items .swiper-button-next:after,
    .customers-review-items .swiper-rtl .swiper-button-prev:after {
        font-size: 1rem;
    }

    .customers-review-items .swiper-button-prev,
    .customers-review-items .swiper-rtl .swiper-button-next {
        padding: 1rem !important;
    }

    .customers-review-items .swiper-button-prev:after,
    .customers-review-items .swiper-rtl .swiper-button-next:after {
        font-size: 1rem;
    }
}