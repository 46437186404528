.order_history_container .inner_page_breadcrumbs {
    align-items: flex-start;
}

.order_history_container {
    margin-top: 3rem;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.order_history-title h2 {
    margin: 1rem 0rem;
    font-size: var(--big-font-size);
    text-align: center;
    font-family: Lato-Regular;
    letter-spacing: 0.0;
    line-height: 1.8;
    text-transform: uppercase;
    color: #000000;
}

.order_history_container .order_history_items {
    display: flex;
    align-content: center;
    justify-content: flex-start;
    flex-direction: column;
    gap: 1rem;
}

.order_history_container .order_history_items .orders_item {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    border: 1px solid #c1c1c1;
    padding: 1rem;
    border-radius: 5px;
}

.order_history_container .order_history_items .orders_item .orders_item_info {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.order_history_container .order_history_items .orders_item .orders_item_info .text-infos h2 {
    font-size: var(--font-size);
    color: #000000;
    font-size: 14px;
    text-transform: uppercase;
    letter-spacing: .07em;
    width: 220px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.order_history_container .order_history_items .orders_item .orders_item_info .text-infos p {
    color: #000000;
    font-family: Lato-Regular;
    display: block;
    font-size: 17px;
    margin-top: 5px;
}

.order_history_container .order_history_items .orders_item img {
    width: 6rem;
}

.order_history_container .order_history_items .orders_item .orders_item_status {
    display: flex;
    flex-direction: column;
    gap: 7px;
}

.order_history_container .order_history_items .orders_item .orders_item_status .delivery-status {
    display: flex;
    align-items: center;
    gap: .5rem;
}

.order_history_container .order_history_items .orders_item .orders_item_status .delivery-status .dot-color-warning {
    border-radius: 50%;
    background-color: orange;
    height: 12px;
    width: 12px;
}

.order_history_container .order_history_items .orders_item .orders_item_status p {
    color: #000000;
    white-space: nowrap;
    font-family: Lato-Regular;
    display: block;
    font-size: 17px;
}

.order_history_container .order_history_items .orders_item .orders_item_status .delivery-tracking .track-order-btn {
    display: flex;
    align-content: center;
    justify-content: center;
    gap: .5rem;
    color: #000000;
    font-size: 17px;
    font-family: Lato-Regular;
    background-color: lightgreen;
    padding: .5rem;
    border-radius: 20px;
}

.order_history_container .order_history_items .orders_item .orders_item_status .delivery-tracking .track-order-btn i {
    color: #000000;
}

.order_history_container .order_history_items .orders_item .orders_item_actions {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.order_history_container .order_history_items .orders_item .orders_item_actions .orders_item_actions-btn {
    cursor: pointer;
    border: 1px solid #c1c1c1;
    color: #000000;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.3rem;
    border-radius: 5px;
    font-size: var(--font-size);
}

.order_history_container .order_history_items .orders_item .orders_item_actions .orders_item_actions-btn:hover {
    border-color: #000000;
}

.order_history_container .order_history_items .orders_item .orders_item_actions i {
    color: #DC747D;
}

.rating-container{
    display: flex;
    justify-content: center;
}

/* md */
@media (max-width: 768px) {
    .order_history_container .order_history_items .orders_item {
        gap: 1rem;
    }

    .order_history_container .order_history_items .orders_item .orders_item_actions {
        flex-direction: row;
        flex-wrap: wrap;
    }
}
