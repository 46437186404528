.contact_us-title h2 {
    text-align: center;
    font-size: var(--big-font-size);
    font-family: Lato-Regular;
    letter-spacing: 0.0;
    line-height: 1.8;
    text-transform: uppercase;
}

.contact_us-paragraph {
    text-align: center;
    margin-top: 1rem;
    color: #000;
    letter-spacing: 0.025em;
    line-height: 1.4;
    font-size: var(--font-size);
    text-transform: capitalize;
    font-family: Lato-Regular;
    margin-bottom: 50px;
}

.table_wrapper {
    max-width: 100%;
    width: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    margin-bottom: 2rem;
}

.table_wrapper table {
    max-width: 1000px;
    margin: 0 auto;
    border-spacing: 1px;
    border-collapse: collapse;
    border: 1px solid #c1c1c1;
    margin-bottom: 20px;
}

.table_wrapper table tbody {
    display: table-row-group;
    vertical-align: middle;
    border-color: inherit;
}

.table_wrapper table tr td {
    width: 50%;
    padding: 10px 15px;
    background: #fff;
    border: 1px solid #c1c1c1;
    text-align: left;
    color: #000;
    letter-spacing: 0.025em;
    line-height: 1.4;
    font-size: var(--font-size);
    text-transform: capitalize;
    font-family: Lato-Regular;
}

.contact_us-form {
    padding: 2rem 0rem;
    display: flex;
    flex-direction: column;
    max-width: 1000px;
    margin: 0 auto;
}

.contact_us-form .flex-form-field {
    display: flex;
    gap: 1rem;
}

.contact_us-form .flex-form-field .text-field {
    width: 50%;
}

.contact_us-form .text-area-label {
    display: block;
    margin-bottom: 10px;
    text-transform: uppercase;
    letter-spacing: .3em;
    font-family: Lato-Regular;
    font-size: .9em;
}

.contact_us-form textarea {
    display: block;
    border: 1px solid;
    border-color: #c1c1c1;
    padding: 8px 10px;
    border-radius: 0;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
    max-width: 1000px;
}

.contact_us-form .send-button-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contact_us-form .send-button-div p a {
    color: #000;
}

.contact_us-form .send-button-div p a:hover {
    color: #000;
}

.contact_us-form .send-btn {
    width: fit-content;
    margin: 2rem 2rem;
}

.contact_us-form textarea:focus-visible {
    outline: none;
}

.contact-success{
    padding: 2rem;
    margin-top: 10px    ;
    background-color: rgb(134, 243, 134);
    color: #fff;
    border-radius: 10px;
}

@media (max-width: 768px) {
    .contact_us-paragraph {
        font-size: var(--font-size);
    }

    .table_wrapper table tr td {
        padding: 6px 8px;
        width: 50%;
        font-size: var(--a-font-size);
    }
}