.my-profile-container {
    margin-top: 1rem;
    margin-bottom: 4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.my-profile-title h2 {
    font-size: var(--big-font-size);
    font-family: Lato-Regular;
    letter-spacing: 0.0;
    line-height: 1.8;
    text-transform: uppercase;
    color: #000000;
}

.my-profile-container .action-buttons {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.my-profile-container .action-buttons .logout-btn {
    padding: 10px 20px;
    background-position: 150% 45%;
    min-width: 90px;
    color: #000;
    font-family: Lato-Regular;
    font-size: 14px;
    line-height: 1.42;
    border-radius: 3px;
    border: 1px solid;
    border-color: #c1c1c1;
    background-color: transparent;
}

.my-profile-container .action-buttons .logout-btn:hover {
    border-color: #000000;
}

.my-profile-container .action-buttons .wishlist-btn {
    color: #000;
    text-decoration: none;
    background: transparent;
    letter-spacing: 0.025em;
    line-height: 1.4;
}


.my-profile-container .my-profile-contents {
    margin-top: 2rem;
    width: 80%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}

.my-profile-container .my-profile-contents .my-profiles-options {
    flex: 60%;
}

.my-profile-container .my-profile-contents .my-profiles-options .pro-options {
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: .5rem;
}

.my-profile-container .my-profile-contents .my-profiles-options .pro-options .option-btn {
    cursor: pointer;
    border: 1px solid #c1c1c1;
    color: #000000;
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    gap: .3rem;
    border-radius: 5px;
    font-size: var(--font-size);
}

.my-profile-container .my-profile-contents .my-profiles-options .pro-options .option-btn:hover {
    border-color: #000000;
}

.my-profile-container .my-profile-contents .my-profiles-options .pro-options .option-btn i {
    color: #DC747D;
}

.my-profile-container .my-profile-contents .my-profiles-account-details {
    flex: 40%
}

.my-profile-container .my-profile-contents .my-profiles-options h2 {
    text-transform: uppercase;
    font-size: var(--big-font-size);
    font-family: Lato-Regular;
    letter-spacing: 00;
    line-height: 1.8;
    color: #000;
}

.my-profile-container .my-profile-contents .my-profiles-account-details h3 {
    text-transform: uppercase;
    font-size: var(--font-size);
    font-family: Lato-Regular;
    letter-spacing: 00;
    line-height: 1.8;
    color: #000;
}

.my-profile-container .my-profile-contents .my-profiles-account-details .my-profile-name {
    text-transform: uppercase;
    letter-spacing: .3em;
    font-size: .8em;
    margin-bottom: 10px;
    color: #000;
}

.my-profile-container .my-profile-contents .my-profiles-account-details .profile-address {
    font-family: Lato-Regular;
    letter-spacing: 0.025em;
    line-height: 1.4;
    color: #000;
    margin: 0 0 15px;
}

.my-profile-container .my-profile-contents .my-profiles-account-details .view-address-btn {
    color: #000;
    text-decoration: none;
    font-size: var(--font-size);
    display: flex;
    align-items: center;
    gap: .3rem;
}

.my-profile-container .my-profile-contents .my-profiles-account-details .view-address-btn i {
    color: #DC747D;
}

@media (max-width: 769px) {
    .my-profile-container .my-profile-contents {
        flex-direction: column;
        width: 100%;
    }

    .my-profile-container .my-profile-contents .my-profiles-account-details {
        margin-top: 1rem;
    }

}