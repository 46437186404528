.tracking-details-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
}

.timeline {
  padding: 3rem 2rem;
  width: 100%;
  max-width: 50%;
  border-radius: 12px;

  border: 1px solid #d9dbde;
  border-radius: 4px;
}

@media (max-width: 768px) {
  .timeline {
    max-width: 100%;
  }
}

.tl-content .tl-header,
.tl-content .tl-body {
  padding-left: 25.6px;

  border-left: 3px solid gainsboro;
}

.tl-body {
  padding-bottom: 1rem;
}

.tl-content:last-child .tl-body {
  border-left: 3px solid transparent;
}

.tl-header {
  position: relative;
  display: grid;
  gap: .5rem;
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tl-title {
  font-weight: 600;
  font-size: 1em;
  padding-bottom: .3rem;
  border-bottom: 1px solid gainsboro;
}

.tl-time {
  font-size: 0.7em;
}

.tl-marker {
  display: block;
  position: absolute;

  width: 16px;
  height: 16px;
  border-radius: 50% / 50%;

  background: gainsboro;

  left: -1.1rem;
  top: 50%;

  transform: translate(50%, -50%);
}

.tl-content-active .tl-marker {
  padding: 1.6px;

  left: -1.25rem;

  width: 18px;
  height: 18px;

  border: 2px solid limegreen;

  background-color: limegreen;
  background-clip: content-box;

  box-shadow: 0 0 15px -2px limegreen;
}

.tl-content-active .tl-title {
  font-weight: 700;
  padding-bottom: .3rem;
  color: green;
}