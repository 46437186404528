.wishlist-section {
    margin-bottom: 3rem;
}

.wishlist-title-area {
    display: block;
    border-bottom: 1px solid lightgray;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
}

.wishlist-title-area h2 {
    font-size: 26px;
    font-family: Lato-Regular;
    letter-spacing: 0.0em;
    line-height: 1.8;
    color: #000;
}

.wishlist-search-inputs {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
}

.wishlist-search-bar {
    position: relative;
    margin-right: 30px;
    flex: 1;
    max-width: 100%;
    border: 1px solid lightgray;
    background-color: transparent;
}

.wishlist-search-bar i {
    position: absolute;
    font-size: 1.5rem;
    padding: 0.5rem;
    color: #555;
}

.wishlist-search-bar input {
    width: 100%;
    height: 100%;
    padding-left: 46px;
    display: table-cell;
    vertical-align: middle;
    margin: 0;
    color: #555;
    font-size: var(--font-size);
    font-family: Lato-Regular;
    letter-spacing: 0.025em;
    line-height: 1.4;
}

.wishlist-search-bar input::-webkit-search-cancel-button {
    height: 20px;
    width: 20px;
    color: #555 !important;
    margin-right: .5rem;
    border-radius: 10px;
}

.wishlist-list-view {
    display: flex;
    flex-direction: column;
}

.wishlist-view {
    display: flex;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    height: 46px;
    overflow: hidden;
}

.change__view {
    display: block;
    background: transparent;
    border: 0;
    padding: 14px 15px;
    color: #d1d1d1;
    line-height: 1;
    cursor: pointer;
}

.change__view {
    display: block;
    background: transparent;
    border: 0;
    padding: 12px 15px;
    color: #d1d1d1;
    line-height: 1;
    cursor: pointer;
    font-size: 1.3rem;
}

.change__view-active {
    cursor: default;
    color: #525252;
    background: #f2f2f2;
    font-size: 1.3rem;
}


.wishlist-grid-view {
    display: flex;
    flex-direction: row;
    /* justify-content: space-between; */
    flex-wrap: wrap;
}

.wishlist-grid-view .wishlist_product {
    width: 24%;
    margin: 0.4rem;
}

.select-all-wish-list-items label {
    margin-left: .5rem;
}

.select-all-wish-list-items a,
.select-all-wish-list-items p,
.select-all-wish-list-items label {
    font-family: Lato-Regular;
    letter-spacing: 0.025em;
    line-height: 1.4;
    font-size: var(--font-size);
    color: #000;
}

.select-all-wish-list-items span {
    margin: 0 10px;
    font-family: Lato-Regular;
    letter-spacing: 0.025em;
    line-height: 1.4;
    font-size: var(--font-size);
}

.delete-all-btn {
    cursor: pointer;
}

@media (max-width: 1280px) {
    .wishlist-grid-view .wishlist_product {
        width: 30%;
        margin-top: 1rem;
    }
}

@media (max-width: 991px) {
    .wishlist-grid-view .wishlist_product {
        width: 33%;
        margin: 0.2rem;
    }
}

@media (max-width: 768px) {
    .wishlist-grid-view .wishlist_product {
        width: 48%;
        margin-top: 1rem;
    }
}


.wishlist-empty {
    display: grid;
    width: 100%;
    flex-wrap: wrap;
    flex-direction: row;
    text-align: center;
    font-size: x-large;
}

.wishlist-empty p{
    font-size: var(--font-size);
    margin: 2rem 4rem;
}

.wishlist-empty a{
    font-size: var(--a-font-size);
    margin-bottom: 1.5rem;
}

.btn-empty-confirm {
    display: flex;
    justify-content: center;
}

.confirm-btn {
    width: 100% !important;
    margin-left: 10px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
}