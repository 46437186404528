.reset_password_form_container {
    max-width: 450px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 50vh;
}

.reset_password-form-title {
    text-align: center;
}

.reset_password-form-title h1 {
    font-size: var(--big-font-size);
    font-family: Lato-Regular;
    letter-spacing: 0.0;
    line-height: 1.8;
    text-transform: uppercase;
    color: #000000;
    margin: 0 0 15px;
}

.reset_password-form-title p {
    margin: 0 0 15px;
    font-family: Lato-Regular;
    font-size: var(--font-size);
    letter-spacing: 0.025em;
    line-height: 1.4;
}


.reset_password-form-contents .forgot_password_link {
    text-align: right;
    margin-bottom: 10px;

}

.reset_password-form-contents .forgot_password_link a {
    color: #000;
    text-decoration: none;
    letter-spacing: 0.025em;
    line-height: 1.4;
    background: transparent;

    font-family: Lato-Regular;
}

.reset_password_form_container .create_account_link {
    text-align: center;
}

.reset_password_form_container .create_account_link p {
    margin: 0 0 15px;
}

.reset_password_form_container .create_account_link p a {
    color: #000;
    text-decoration: none;
    background: transparent;
    letter-spacing: 0.025em;
    line-height: 1.4;
    font-size: var(--font-size);
    font-family: Lato-Regular;
}

.sign-in-btn {
    width: 100%;
    margin: 0 0 15px;
}