
.edit-profile_form_container {
    max-width: 600px;
    padding-top: 10px;
    padding-bottom: 10px;
    height: 80vh;
}

.edit-profile-form-title {
    text-align: center;
}

.edit-profile-form-title h1 {
    font-size: var(--big-font-size);
    font-family: Lato-Regular;
    letter-spacing: 0.0;
    line-height: 1.8;
    text-transform: uppercase;
    color: #000000;
    margin-bottom: 30px;
}


.signup-form-contents .forgot_password_link {
    text-align: right;
    margin-bottom: 10px;

}

.signup-form-contents .forgot_password_link a {
    color: #000;
    text-decoration: none;
    letter-spacing: 0.025em;
    line-height: 1.4;
    background: transparent;

    font-family: Lato-Regular;
}

.sign-in-btn {
    width: 100%;
    margin: 0 0 15px;
}

.field-error-text {
    text-transform: uppercase;
    letter-spacing: .3em;
    font-family: Lato-Regular;
    font-size: .7em;
    color: red;
    margin-bottom: 20px;
}

.edit-profile-btn-div{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1rem;
}

@media (max-width: 769px) {
    .edit-profile-btn-div{
        flex-direction: column;
        gap: 0;
    }
}

.edit-profile-cancel-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}
.edit-profile-cancel-btn button{
    width: fit-content;
}

.text-field-container {
    position: relative;
    
}

.text-field-input {
    padding-right: 40px;
    /* Adjust the padding to accommodate the button */
}

.verify-email-btn {
    position: absolute;
    color: #DC747D;
    top: 45%;
    /* Adjust as needed */
    right: 10px;
    /* Adjust as needed */
    transform: translateY(-50%);
}
